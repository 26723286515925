*{
    padding: 0;
    margin: 0;
}

.MAMheading{
    margin: 0 5vw 0 5vw;
    display: flex;
    align-items: center;
}

.MAMactivity-image{
    width: 70px;
    margin-right: 20px;
}

.question-text {
    font-size: 35px; /* Change to your desired font size */
    /*font-weight: bold; /* Optional: to make the text bold */
    line-height: 1.5; /* Optional: for better spacing */
}

.MAMactivity-image img{
    width: 100%;
}

.MAMactivity-text{
    color: rgb(227, 135, 35);
    font-size: 20px;
}

.MAMcontainer{
    margin: 0 5vw 0 5vw;
}

.MAMhead{
    display: flex;
}

.MAMquestions{
    flex: 5;
    background-color: rgb(227, 135, 35);
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: aliceblue;
    border-radius: 10px 0 0 10px;
    
}


#total-questions{
    margin-left: 2px;
}

.MAMprogress-bar{
    height: 5px;
    width: 130px;
    background-color: rgb(154, 168, 168);
    border-radius: 5px;
    margin-top: 5px;
}

#progress{
    height: 5px;
    width: 0;
    background-color: aqua;
    border-radius: 5px;
}

.MAMscore{
    flex: 1;
    padding-top: 3px;
    display: flex;
    flex-direction: column;
    background-color: rgb(64, 163, 64);
    color: white;
    text-align: center;
    border-radius: 0px 10px 10px 0;
    justify-content: space-between;
}

.MAMnumbered-score{
    background-color: rgb(158, 196, 158);
    border-radius: 15px;
    padding: 10px;

}

.MAMbody{
    background-color: rgb(250, 240, 214);
    display: flex;
    border-radius: 15px 0 0 15px;
}

.MAMremaining-body{
    background-color: rgb(250, 240, 214);
    flex: 5;
    padding: 20px;
    display: grid;
    grid-template-columns: 4fr 3fr 1fr;
    column-gap: 10px;
    row-gap: 20px;

    border-radius: 15px 0 0 15px;

    
}

.MAMquestion{
    border-width: 2px;
    border-color: rgb(227, 135, 35);
    border-style: solid;
    text-align: center;
    color: rgb(227, 135, 35);
    font-size: 30px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 0.MAM5fr;
}


.MAMmental-question-section{
    display: flex;
    justify-content: center;
}

.MAMmental-question-section h4{
    margin-top: 15px;
}

.MAMmental-question-section img{
    width: 30px;
    margin-right: 5px;
}

.MAMplay-question{
    background-color: rgb(250, 240, 214);
    border: none;
    cursor: pointer;
}

.MAMplay-question img{
    flex: 1;
    background-color: rgb(250, 240, 214);
    border: none;
    cursor: pointer;
}

.MAMplay-question img:active{
    width: 90px;
}

.MAMpicture img{
    width: 300px;
}


.MAMtime{
    display: flex;
    border: 2px solid red;
    height: 40px;
    border-radius: 20px;
    margin-bottom: 10px;
}

.MAMtime-text{
    background-color: red;
    color: white;
    border-radius: 18px;
    display: flex;
    align-items: center;
    padding: 15px;
}

.MAMtimer{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 15px;
}

.MAMmarks{
    display: flex;
    border: 2px solid rgb(170, 96, 0);;
    height: 40px;
    border-radius: 20px;
    margin-bottom: 10px;
}

.MAMmarks-text{
    background-color: rgb(170, 96, 0);
    color: white;
    border-radius: 18px;
    display: flex;
    align-items: center;
    padding: 15px;
}

.MAMmarker{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 15px;
}

.MAMinstructions-dropdown{
    background-color: rgb(250, 240, 214);
    color: rgb(227, 135, 35);
    padding: 15px;
    font-size: 15px;
}

.MAManswer-submit input::placeholder{
    font-size: 15px;
}

.MAManswer{
    border: none;
    border-radius: 8px;
    padding: 15px 10px;
}

#submission{
    border: none;
    background-color: rgb(18, 18, 192);
    border-radius: 8px;
    color: white;
    padding: 15px 15px;
    cursor: pointer;
}

#marks-color{
    flex: 1;
    background-color: rgb(254, 236, 242);
    border: 1px solid rgb(227, 135, 35);
    border-radius: 0 10px 10px 0;
    overflow: scroll;
    max-height: 300px;
}

.MAMbox{
    display: flex;
    border: 2px solid rgb(227, 135, 35);
    margin: 0 10px 2px 10px;
}

.MAMbox1{
    flex: 1;
    border-width: 0 2px 0 0;
    border-color: rgb(227, 135, 35);
    border-style: solid;
    text-align: center;
}
.MAMbox2{
    flex: 1;
    background-color: red;
    
}

.MAMremarks{
    margin: 0 5vw 0 5vw;
    display: flex;
    justify-content: space-between;
}

.MAOanswer-submit {
    display: flex;
    /* flex-direction: column; Stack items vertically */
     gap: 30px; /* Adds spacing between elements */
  } 
