.TeacherComponents
{
    width: 100%;
    height: auto;
    padding: 1%;
}
.TeacherBankDetails,.TeacherPersonalDetails
{
    width: 30%;
    height: auto;
    background-color: #d3e3da;
    padding: 3%;
    float: left;
    border-radius: 2vh;

}
.TeacherpersonalDetails
{
    width: 30%;
}

.TeacherINP,.TeacherInp
{
    width: 60%;
    height: auto;
    border: 0;
    border-radius: 1vh;
    margin:  2% 2% 2% 5%;
    padding: 2%;
}
 
.BankBtn,.btn1,.btn2
{
    padding: 3% 6% 3% 6%;
    background-color:#03b4c6;
    font-weight: bold;
    color: white;
    font-family: sans-serif;
    border: 0;
    border-radius: 1vh;
    margin-left: 15%;
}
.btn1
{
    margin-left: 2%;
}
.btn2
{
    margin-left: 15%;

}
/* .btn:hover{
    background-color:white ;
    color:#03b4c6 ;
} */

.edit-bar{
    min-width: auto;
    order: 2;
    min-height: auto; 
    height: max-content;
    flex-grow: 0;
    flex-shrink: 0;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
 
    background-color: rgb(0, 100, 128);


}
.edit-heading{
    margin-left: 40%;
    width: auto;
    height: auto;
    flex-grow: 1;
    font-family: "Open Sans";
    font-size: 50px;
    font-weight: 300;
    color: rgb(255, 255, 255);
    line-height: 1.3;
    border-radius: 0px;
} 

.meetLink,.courses
{
    width: 65%;
    padding: 0 8px 0 2%;
    height: auto;
    float: right;
    margin-left: 1px;
    background-color:#d3e3da ;
}
.courses
{
    margin-top: 2%;
    padding: 2% 3% 2% 3%;

}
.monday
{
    padding:  2% 4% 2% 4%;
    border-radius: 2vh;
    margin-left: 1%;
    margin-bottom: 1%;
}
.EditTecherInp 
{
    width: 100%;
    padding: 1vh 1vh 1vh 1vh;
    margin: 0px 1vh 0px 1vh;
    border-radius: 2vh;
    height: auto;
    border: 0;    
}
.DegreeUpload{
    display: flex;
    width: 40%;
    height: 10%;
    border-radius: 1vh;
    border: 2px dotted;
    padding: 2%;
    background-color:rgb(238, 237, 237);
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.education
{
    display: flex;
    flex-wrap: wrap;
    width: 50%;
}
.EditTecherInp{
    margin: 1vh 0 1vh 0;
}

.tableInfo
{
    display: flex;
    background-color: #34979c;
    height: auto;
    width: 94%;
    margin-left: 4%;
    border-radius: 2vh;
}
@media screen and (max-width: 1000px) 
  {
      .edit-heading
      {
          text-align: center;
          margin-left: 0;
      }
      .TeacherComponents
      {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .TeacherBankDetails,.meetLink,.courses,.TeacherPersonalDetails
      {
        width: auto;
        margin: 2% 0 2% 0;
      }


    
}