.manage-lesson-activities {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  background-color: #a9f192;
  max-height: 350px;
  overflow-y: auto;
}

.manage-lesson-activities h2 {
  text-align: center;
  margin-bottom: 20px;
}

.activity-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
  margin-bottom: 20px;
}

.activity-card h3 {
  font-weight: bold;
  margin-bottom: 10px;
}

.activity-type {
  font-style: italic;
}

.buttonsinz {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-button {
  background-color: #5feafc;
}

.delete-button {
  background-color: red;
}


.assign-form {
  background-color: #27b151;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.assign-form h3 {
  margin-top: 0;
  font-size: large;
}

.form-group {
  margin-bottom: 10px;
}

.custom-label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.custom-input,
.custom-textarea,
.custom-select {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.custom-aqua-button {
  margin-left: 5px;
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 3px;
  background-color: #00bcd4;
  color: #fff;
  cursor: pointer;
}

.add-button {
  display: block;
  margin: 0 auto;
  padding: 20px;
  font-size: 16px;
  border-radius: 5px;
  background-color: #00bcd4;
  color: #fff;
  cursor: pointer;
}

.add-button:hover,
.custom-aqua-button:hover,
.edit-button:hover,
.delete-button:hover,
.assign-button:hover {
  background-color: #f3f8f9;
}

.activity-card {
  background-color: #f0ed65;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.activity-name {
  font-weight: bold;
  margin-bottom: 10px;
}

.activity-description {
  margin-bottom: 10px;
}

.buttons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.edit-button,
.delete-button,
.assign-button {
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 10px;
}

.assign-button {
  background-color: rgb(78, 173, 252);
  color: black;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
}

.modal-content {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.copy-activity-form {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  max-width: 400px;
  margin: 0 auto;
}

.copy-activity-form FormGroup {
  margin-bottom: 15px;
}

.copy-activity-form Label {
  display: block;
  margin-bottom: 5px;
}

.copy-activity-form Input[type="select"] {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.copy-activity-form button {
  background-color: #4caf50;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.copy-activity-form button:hover {
  background-color: #45a049;
}

/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}


.modalStyle {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 400px;
  max-height: 500px;
  overflow-y: auto;
  border-radius: 30px;
}
