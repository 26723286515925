/* General Styles */
html, body {
    margin: 0;
    padding: 0;
    font-family: 'Baumans';
    background-color: #e0f7fa;
    box-sizing: border-box;
}

/* Polymatch Background Section */
.PolymatchBgg {
    background: url('../CourseImages/CeoMsgBg.png') no-repeat center center fixed;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 5vh 0;
}

/* Polymatch Logo Section - Center content */
.PolymatchSection_11 {
    position: relative; /* Needed for the absolute positioning of the circle */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2vh;
    width: 50%;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.PolymatchSection_11 img {
    width: 60%; /* Adjusted for better fit */
    height: auto;
    margin-top: 0;
}

/* Competition Details Section */
.PolymatchSection_12 p {
    font-family: 'Baumans', sans-serif; /* Matches the font-family */
    font-size: 35px; /* Adjusted font size */
    font-weight: 800; /* Normal font weight */
    color: rgb(139, 1, 148); /* Color as in the div */
    line-height: 1.4; /* Line height for spacing */
    white-space: pre-wrap; /* Maintains whitespace formatting */
    word-break: break-word; /* Breaks words to avoid overflow */
    margin: 0; /* Remove margins for clean text wrapping */
    width: calc(100% + 0px); /* Full-width adjustment */
    opacity: 1; /* Full visibility */
    overflow: visible; /* Ensures content visibility */
    min-width: 200px; /* Ensure a minimum width */
    min-height: 36px; /* Minimum height as per the requirement */
    height: max-content; /* Ensures the height adapts to content */
}

/* Adjust h1 to align text and provide margin */
.PolymatchSection_11 h1 {
    text-align: center;
    margin-bottom: 1rem;
}

.PolymatchSection_12 h1 {
    font-size: 3rem;
    color: #8e24aa; /* Purple theme */
    margin-bottom: 2vh;
    
    
}

.PolymatchSection_12 p {
    font-family: 'Baumans', sans-serif; /* Matches the font-family */
    font-size: 20px; /* Adjusted font size */
    font-weight: 400; /* Normal font weight */
    color: rgb(139, 1, 148); /* Color as in the div */
    line-height: 1.4; /* Line height for spacing */
    white-space: pre-wrap; /* Maintains whitespace formatting */
    word-break: break-word; /* Breaks words to avoid overflow */
    margin: 0; /* Remove margins for clean text wrapping */
    width: calc(100% + 0px); /* Full-width adjustment */
    opacity: 1; /* Full visibility */
    overflow: visible; /* Ensures content visibility */
    min-width: 200px; /* Ensure a minimum width */
    min-height: 36px; /* Minimum height as per the requirement */
    height: max-content; /* Ensures the height adapts to content */
}


.PolymatchSection_12 a {
    color: #8e24aa;
    text-decoration: none;
    font-size: 2vh;
}

.PolymatchSection_12 a:hover {
    text-decoration: underline;
}

/* Text container - to ensure the logo and text don't overlap */
.PolymatchSection_12 {
    position: relative;
    margin-top: 20px;
    padding: 0 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* New container to hold the text and the date circle */
.polymatch-text-container {
    display: flex;
    align-items: center; /* Aligns text and circle vertically */
    justify-content: flex-start; /* Ensures content starts from the left */
    position: relative;
}

.event-date-circle {
    position: absolute; /* Absolute positioning to control the exact position */
    top: 75%; /* Vertically align in the middle of the container */
    right: 150px; /* Adjust the distance from the right edge */
    transform: translateY(-50%); /* Center the circle vertically */
    background-color: #9c27b0;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.2rem;
    text-align: center;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Disclaimers Section */
.detail-section {
    width: 80%;
    margin: 5vh auto;
    border-radius: 15px;
    padding: 4vh;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: justify;
}

.detail-section h1 {
    
    color: rgb(139, 1, 148);
    margin-bottom: 15px;
    font-family: 'Baumans';
    font-size: 2rem;
    margin-bottom: 2vh;
    border-radius: 15px;
    text-align: center;
}

.detail-section p {
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 2vh;
    justify-content: flex-end;
    align-items: center;
}

.detail-section p strong {
    color: #e53935; /* Highlight disclaimers */
}

/* Footer */
footer {
    margin-top: 5vh;
    padding: 20px;
    background-color: #c5e1a5;
    text-align: center;
    color: #333;
    font-size: 1rem;
    border-top: 1px solid #e0e0e0;
}
