.Astronomy-Background{
    width: 100%;
    height:600px;
    display: flex;
    background:  url('./SpaceSciencesImages/portalBackground.png');
    background-size: cover; 
    position: relative;
}

@media only screen and (max-width: 1000px)
{
    .Astronomy-Background{

        height: auto;
        width: auto;
    }
    .feeTableContainer
{
    display: flex;  
    width: 100%;
    margin: 0;
}
.columns {
    flex: 1;
    min-width: 100%; /* Each section takes up the full width */
    padding: 20px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    text-align: center;
    transition: transform 0.3s ease-in-out; /* Add slide transition */
  }
    .feeTableContainer
    {
        overflow-x: scroll; /* Enable horizontal scrolling for mobile */
        scroll-snap-type: x mandatory;
        /* white-space: nowrap; */
    }
    .columns {
        min-width: 100%; /* Each section takes up the full width */
        scroll-snap-align: start;
      }
}