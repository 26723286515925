.Webdev-Backgroundd{
    width: 100%;
    height:600px;
    display: flex;
    background:  url('./CodingCourseImages/portalBackground.png');
    background-size: cover; 
    position: relative;
}
.WebDevActivitiesImg{
    width: 13vw;
    height: 20vh;
}
.PageDescr
{
    
    font-family: 'DM Sans', sans-serif;
    color: white;
    text-align: left;
    padding: 30px;
    font-size: 22px;
    z-index: 1;
}

@media only screen and (max-width: 1000px)
{
    .Webdev-Background
    {
        height: auto;
        width: auto;
    }
    .WebDevActivitiesImg{
        width: 50vw;
        height: 20vh;
    }
}