/* General layout */
html, body {
    height: 100vh; /* Ensure full height for the body */
    margin: 0; /* Remove default margin */
    /* overflow: hidden;  Prevent scrolling */
}

.registration-container {
    display: flex;
    justify-content: space-around; /* Center content */
    align-items: center;
    /* height: 100vh;  */
    background-color: #f8f8f8;
    padding: 20px;
}

.registration-left {
    flex: 0 0 40%; /* Set width to 40% */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    
}

.polymatch-image {
    max-width: 100%; /* Make image responsive */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 20px;
}

h1 {
    font-size: 2.5rem; /* Responsive font size */
    font-family: 'Baumans', cursive; /* Use Google Fonts for Baumans if not installed */
    font-weight: bold;
    color: rgb(139, 1, 148); /* Purple color */
    margin-bottom: 10px;
}

.registration-right {
    flex: 0 0 40%; /* Set width to 40% */
    max-width: 500px; /* Limit max width */
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;
    border-radius: 8px;
}

form {
    display: flex;
    flex-direction: column;
}

h2 {
    font-size: 1.2rem; /* Responsive font size */
    color: #333;
    margin-bottom: 15px;
}

PReg {
    font-size: 0.9rem; /* Responsive font size */
    color: #666;
    margin-bottom: 20px;
}

input, select {
    width: 100%; /* Full width inputs */
    padding: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem; /* Responsive font size */
}

.file-upload {
    margin-bottom: 20px;
}

.file-upload input {
    display: none;
}

.file-upload label {
    display: block;
    cursor: pointer;
    padding: 10px;
    background-color: #f9f9f9;
    border: 2px dashed #bbb;
    color: #666;
    text-align: center;
    font-size: 0.9rem;
}

.file-upload:hover label {
    background-color: #e6e6e6;
}

/* Purple register button */
.register-button {
    width: 100%; /* Full width button */
    padding: 14px;
    background-color: #702daa; /* Purple color */
    color: #fff;
    font-size: 1.1rem; /* Responsive font size */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
    margin-left: 46px;
}

.register-button:hover {
    background-color: #553785; /* Darker purple on hover */
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .registration-container {
        flex-direction: column; /* Stack vertically on smaller screens */
        height: auto; /* Allow container to grow with content */
    }
    .registration-left,
    .registration-right {
        flex: 1 1 auto; /* Allow elements to take full width */
        max-width: 100%; /* Prevent overflow */
    }
}
.image-preview {
    margin-bottom: 20px;
    text-align: center;
}

.preview-image {
    max-width: 200px;
    max-height: 200px;
    border-radius: 8px;
    object-fit: cover;
}
