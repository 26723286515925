.Gamedev-Background{
    width: 100%;
    height:600px;
    display: flex;
    background:  url('./CodingCourseImages/portalBackground.png');
    background-size: cover; 
    position: relative;
}
.carousel .carousel-item .img-box1 {
    
    background:  url('./CodingCourseImages/testimonalGamedev.png'); 
    background-size: cover;
    background-position: center;
    position: relative;
    background-repeat:no-repeat ;
	width: 135px;
	height: 135px;
	margin: 0 auto;
	padding: 5px;
	border: 1px solid #ddd;
	border-radius: 50%;
}


@media only screen and (max-width: 1000px)
{
    .Gamedev-Background
    {
        height: auto;
        width: auto;
    }
}