
.calendar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    overflow: auto;
  }
  
  .calendar {
    width: 90%;
    max-width: 1200px;
    height: 100%;
  }
  .calendar-container {
    padding: 20px;
  }
  
  .calendar {
    max-width: 900px;
    margin: 0 auto;
  }
  
  .fc-event {
    cursor: pointer;
  }
  
  .fc-event-title {
    font-weight: bold;
  }
  
  .fc-event-time {
    color: #000;
  }
  