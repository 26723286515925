*{
    padding: 0;
    margin: 0;
}

.MAheading{
    margin: 0 5vw 0 5vw;
    display: flex;
    align-items: center;
}

.MAactivity-image{
    width: 70px;
    margin-right: 20px;
}

.MAactivity-image img{
    width: 100%;
}

.MAactivity-text{
    color: blueviolet;
    font-size: 20px;
}

.MAcontainer{
    margin: 0 5vw 0 5vw;
}



.MAhead{
    display: flex;
    max-height: 100px;
}

.MAquestions{
    flex: 5;
    background-color: blueviolet;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: aliceblue;
    border-radius: 10px 0 0 10px;
    
}


#MAtotal-questions{
    margin-left: 2px;
}

.MAprogress-bar{
    height: 5px;
    width: 130px;
    background-color: rgb(154, 168, 168);
    border-radius: 5px;
    margin-top: 5px;
}

#MAprogress{
    height: 5px;
    width: 0;
    background-color: aqua;
    border-radius: 5px;
}

.MAscore{
    max-height: 100px;
    flex: 1;
    padding-top: 3px;
    display: flex;
    flex-direction: column;
    background-color: rgb(64, 163, 64);
    color: white;
    text-align: center;
    border-radius: 0px 10px 10px 0;
    justify-content: space-between;
}

.MAscore-text h4{
    font-size: bold;
    max-height: 20px;
}

.MAnumbered-score{
    background-color: rgb(169, 231, 152);
    border-radius: 15px;
    padding: 10px;
    max-height: 50px;
}

.MAbody{
    background-color: rgba(227, 192, 240, 0.595);
    display: flex;
    border-radius: 15px 0 0 15px;
}

.MAremaining-body{
    background-color: rgba(227, 192, 240, 0.595);
    flex: 5;
    padding: 20px;
    display: grid;
    grid-template-columns: 3fr 2fr 1fr;
    column-gap: 10px;
    row-gap: 20px;

    border-radius: 15px 0 0 15px;

    
}

.MAquestion{
    border-width: 2px;
    border-color: blueviolet;
    border-style: solid;
    text-align: center;
    color: blueviolet;
    font-size: 300px;
    height: 250px;
}

.question-text {
    font-size: 35px; /* Change to your desired font size */
    /*font-weight: bold; /* Optional: to make the text bold */
    line-height: 1.5; /* Optional: for better spacing */
}

.MApicture{
    height: 250px;
    max-width: 300px;
}

.MApicture img{
    height: 100%;
    border: 2px solid rebeccapurple;
    max-width: 300px;
}


.MAtime{
    display: flex;
    border: 2px solid red;
    height: 40px;
    border-radius: 20px;
    margin-bottom: 10px;
}

.MAtime-text{
    background-color: red;
    color: white;
    border-radius: 18px;
    display: flex;
    align-items: center;
    padding: 15px;
    width: 90px;
}
#MAtime-counter{
    color: red;
    font-size: 22px;
}

.MAtimer{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 15px;

}

.MAmarks{
    display: flex;
    border: 2px solid rgb(170, 96, 0);;
    height: 40px;
    border-radius: 20px;
    margin-bottom: 10px;
}

.MAmarks-text{
    background-color: rgb(170, 96, 0);
    color: white;
    border-radius: 18px;
    display: flex;
    align-items: center;
    padding: 15px;
    width: 90px;
}

#MAmarks-per-question{
    color: rgb(170, 96, 0);
    font-size: 22px;
}

.MAmarker{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 15px;
}

.MAinstructions-dropdown{
    background-color: rgba(227, 192, 240, 0.595);
    color: blueviolet;
    padding: 15px;
    font-size: 15px;
}

.MAanswer-submit input::placeholder{
    font-size: 15px;
}

.MAanswer{
    border: none;
    border-radius: 8px;
    padding: 15px 10px;
}

#MAsubmission{
    border: none;
    background-color: rgb(18, 18, 192);
    border-radius: 8px;
    color: white;
    padding: 15px 15px;
    cursor: pointer;
}

#MAmarks-color{
    flex: 1;
    background-color: rgba(227, 192, 240, 0.595);
    border: 1px solid blueviolet;
    border-radius: 0 10px 10px 0;
}

.MAbox{
    display: flex;
    border: 2px solid blueviolet;
    margin: 0 10px 2px 10px;
}

.MAbox1{
    flex: 1;
    border-width: 0 2px 0 0;
    border-color: blueviolet;
    border-style: solid;
    text-align: center;
}
.MAbox2{
    flex: 1;
    background-color: red;
    
}
.MAbox20{
    flex: 1;
    background-color: red;
    
}
.MAbox21{
    flex: 1;
    background-color: green;
    
}

.MAremarks{
    margin: 0 5vw 0 5vw;
    display: flex;
    justify-content: space-between;
}

.MAOanswer-submit {
    display: flex;
    /* flex-direction: column; Stack items vertically */
     gap: 30px; /* Adds spacing between elements */
  } 