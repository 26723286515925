.PolymatchBg{
    background:  url('../CourseImages/CeoMsgBg.png');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5%;
}
.PolymatchWords {
    display: flex;
    justify-content: center;
    gap: -10px;
    margin-top: 0px;
    font-size: 1.8rem;
    color: #6d0283;
    font-family: 'Belanosima';
}

.PolymatchWords p {
    margin: 0;
    font-weight: bold;
}
.PolymatchWords p:nth-child(1) {
    color: rgb(139, 1, 148);  /* Mastery */
}

.PolymatchWords p:nth-child(2) {
    color: rgb(182, 3, 96);  /* Achieve */
}

.PolymatchWords p:nth-child(3) {
    color: rgb(227, 206, 24);  /* Thrive */
}

.PolymatchWords p:nth-child(4) {
    color: rgb(71, 150, 63);  /* Compete */
}

.PolymatchWords p:nth-child(5) {
    color: rgb(227, 206, 24);  /* Hone */
}

.PolymatchHeading {
    margin-top: 20px;
    margin-bottom: 0px;
    font-size: 1.5rem;
    font-weight: bold;
    color: rgb(182, 3, 96);
    text-align: center;
    font-family: 'Belanosima';
}
.PolymatchSection_1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.PolymatchSection_1 img{
    width:30% ;
    height: 30%;
}
.PolymatchSection_1 p{
    padding: 0 1vh 0 1vh;
    font-size: 2.5vh;
    font-family: cursive;
    font-weight: bold;
    text-align: justify;
    color: #8b0194;
    width: 70%;
    margin-bottom: 20px;
}


.PolymatchSection_2 {
    display: flex;
    flex-wrap: wrap; /* Allows wrapping of items */
    align-items: center;
    justify-content: space-between;
    width: 80%;
    gap: 10px; /* Consistent spacing */
    margin-top: 20px; /* Add space above the section if needed */
    margin-left: 10px;
}
.PolymatchSection_2 > div {
    flex: 1 1 30%; /* Adjust the width of each item */
    box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
    max-width: 30%;
    text-align: center
}
.Polymatch_MathMental,.Polymatch_Islamic,.Polymatch_Linguist{
    background-color: #dbaf1f;
    width: 300px; /* Set a fixed width */
    height: 90px; /* Set a fixed height */
    padding: 15px; /* Uniform padding */
    border-radius: 8px; /* Rounded corners */
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth hover effect */
    display: flex; /* Flex to center content */
    flex-direction: column; /* Stack content vertically */
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
}
.Polymatch_Islamic{
    background-color: rgb(78, 237, 64)
}

.Polymatch_Linguist{
    background-color: #688cd9;
}

.Polymatch_MathMental p,
.Polymatch_Islamic p,
.Polymatch_MathMental a,
.Polymatch_Islamic a,
.Polymatch_Linguist p,
.Polymatch_Linguist a{
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: medium;
    align-items: center;
    
}


.PolymatchSection_3 {
    font-family: 'Comic Sans MS', cursive, sans-serif;
    color: #730099;
    background-color: #eaf6ff;
    padding: 20px;
    border-radius: 10px;
    /* max-width: 800px; */
    /* margin: 0 auto; */
    text-align: center;
    margin-left: 150px;
}
.Polymatch_header{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 0px;
}
.Polymatch_header h1 {
    font-size: 36px;
    margin: 0;
}

.Polymatch_header h2 {
    font-size: 24px;
    margin: 0;
}

.Polymatch_dateBadge {
    background-color: #d399ff;
    border-radius: 50%; /* Perfect circle */
    padding: 0; /* Ensure no extra padding affects centering */
    height: 16.5vh; /* Adjust the height */
    width: 16.5vh; /* Match the width with height for a perfect circle */
    display: flex;
    justify-content: center; /* Horizontally center content */
    align-items: center; /* Vertically center content */
    white-space: nowrap; /* Prevent text wrapping */
    text-align: center; /* Centers the text horizontally inside flex */
    margin-right: 100px;
    margin-left: 80px;
}
.Polymatch_dateBadges {
    background-color: #d399ff;
    border-radius: 50%; /* Perfect circle */
    padding: 0; /* Ensure no extra padding affects centering */
    height: 16.5vh; /* Adjust the height */
    width: 16.5vh; /* Match the width with height for a perfect circle */
    display: flex;
    justify-content: center; /* Horizontally center content */
    align-items: center; /* Vertically center content */
    white-space: nowrap; /* Prevent text wrapping */
    text-align: center; /* Centers the text horizontally inside flex */
    margin-right: 100px;
    margin-left: 20px;
}
.Polymatch_dateBadges p {
    margin: 0; /* Remove default paragraph margins */
    font-size: 1.5vh; /* Adjust font size proportionally */
    font-family: 'Barlow, sans-serif';
    font-weight: bold;
    text-align: center;
}

.Polymatch_dateBadge p {
    margin: 0; /* Remove default paragraph margins */
    font-size: 1.5vh; /* Adjust font size proportionally */
    font-family: 'Barlow, sans-serif';
    font-weight: bold;
    text-align: center;
}


.Polymatch_content p {
    padding: 10px;
    border-radius: 10px;
    margin: 80px 90;
    text-align: justify;
    font-weight: lighter;
    margin-top: 0px;
    margin-left: 90px;
    font-family: Baumans; font-size: 20px; font-weight: 400
}
.results-btn {
    display: inline-block; /* Ensures the button stays inline */
    padding: 10px 20px; /* Adjust padding for proper alignment */
    background: -webkit-linear-gradient(top, rgb(255, 255, 255) 0%, rgb(139, 1, 148) 100%);
    background: linear-gradient(to bottom, rgb(255, 255, 255) 0%, rgb(139, 1, 148) 100%);
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    border-radius: 8px;
    white-space: nowrap; /* Prevents text wrapping */
    transition: background-color 0.3s ease;
    margin-top: 0; /* Remove extra margin if causing misalignment */
}

.results-btn:hover {
    background-color: #483d8b; /* Darker Slate Blue */
}

.reg-btn {
    display: inline-block; /* Ensures the button stays inline */
    padding: 10px 20px; /* Adjust padding for proper alignment */
    background: -webkit-linear-gradient(top, rgb(255, 255, 255) 0%, rgb(139, 1, 148) 100%);
    background: linear-gradient(to bottom, rgb(255, 255, 255) 0%, rgb(139, 1, 148) 100%);
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    border-radius: 8px;
    white-space: nowrap; /* Prevents text wrapping */
    transition: background-color 0.3s ease;
    margin-top: 0; /* Remove extra margin if causing misalignment */
    align-items: center;
}

.reg-btn:hover {
    background-color: #483d8b; /* Darker Slate Blue */
}

.regs-button {
    display: flex; /* Enables flexbox */
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    height: 100%; /* Adjust the height if needed */
    width: 100%; /* Ensure it spans the full width of the container */
    text-align: center; /* Aligns text if applicable */
}


.Polymatch_participantsSection {
    margin-top: 20px;
    margin-right: 150px;
    width: fit-content;
}

.Polymatch_participantsSection h3 {
    color: #ff5733;
    font-size: 3vh;
}

.Polymatch_flagsGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    justify-items: center;
    
}
.PolymatchFlagSection{
    display: flex;
    align-items: center;
    /* justify-content: space-around; */
    margin-left: 5vh;
}
.Polymatch_flag{
    width: 80%;
    height: 80%;
}

.gallery_container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.gallery_image {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .PolymatchSection_2{
        flex-direction: column;
    }
    .PolymatchFlagSection{
        flex-direction: column;
        margin-left:0 ;
    }
    .Polymatch_content{
        width: auto;
    }
    .Polymatch_content p{
        text-align: justify;
        width: 100%;
    }
    .Polymatch_dateBadge{
        height: auto;
        width: auto;
    }
}

@media (max-width: 768px) {
    .gallery_image {
        max-width: 100%;
    }
}

@media (max-width: 480px) {
    .gallery_image {
        max-width: 80%;
    }
}
.PolymatchButtons {
    display: flex;
    gap: 20px;
    margin-top: 20px;
}

.polymatch-btn {
    background-color: rgb(139, 1, 148);
    color: white;
    padding: 15px 30px;
    border-radius: 30px;
    text-decoration: none;
    font-weight: bold;
    text-align: center;
    transition: background-color 0.3s ease;
    margin-bottom: 20px;
}

.polymatch-btn.details {
    background-color: rgb(139, 1, 148);
}

.polymatch-btn:hover {
    background-color: #e64e2d;
}

.polymatch-btn.details:hover {
    background-color: #5078b4;
}

@media (max-width: 768px) {
    .PolymatchButtons {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
}
