.manage-lesson-project {
    background-color: lightyellow;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
  }

  .manage-lesson-project h2 {
    color: black;
  }

  .project-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  .project-card {
    background-color: white;
    border-radius: 4px;
    padding: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 300px;
  }

  .edit-form {
    margin-top: 20px;
  }

  .edit-form input,
  .edit-form textarea {
    display: block;
    margin-bottom: 10px;
    width: 100%;
  }

  .mybuttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .aqua-button1 {
    background-color: rgb(54, 168, 168);
    size: 100px;
  }

  .update-button {
    margin-right: 10px;
    background-color: rgb(54, 168, 168);
  }
