.manage-module-project {
  background-color: rgb(255, 124, 255);
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.manage-module-project h2 {
  color: white;
}

.project-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: space-between; /* Adjust spacing between cards */
}

.project-card {
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
  width: 500px;
}

/* Apply styles for screens smaller than 768px */
@media screen and (max-width: 768px) {
  .project-card {
    width: calc(50% - 10px); /* Show two cards in a row */
  }
}

/* Apply styles for screens smaller than 576px */
@media screen and (max-width: 576px) {
  .project-card {
    width: 100%; /* Show one card in a row */
  }
}

.edit-form {
  margin-top: 20px;
}

.edit-form input,
.edit-form textarea {
  display: block;
  margin-bottom: 10px;
  width: 100%;
}

.mybuttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  color: blanchedalmond;
}

.buttoninz {
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.aqua-button {
  background-color: rgb(54, 168, 168);
}

.update-button {
  margin-right: 10px;
  background-color: rgb(54, 168, 168);
}
