.vision{
    width: 100%;  
    height: 70vh;
background-color: #edffff;
}
.innerVision
{
    width: 60%;
    margin-left: 15%;
    text-align: left;
}
.mainHeading
{
    font-family: 'VanillaMilk';
    font-size: 60px;
    color: #8b0194;
    text-align: center;
    z-index: 1;
    padding: 20px;
}
.subHeading
{
    font-family:sans-serif;
    font-size: 30px;
    font-weight: bold;

    color: #8b0194; 
    z-index: 1; 
}
.para
{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size:18px; 
    text-align: left;
    color: #0e0e0e; 
    z-index: 1; 
}