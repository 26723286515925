.TeacherFilterSection{
    height: 10vh;
    width: 100%;
    padding: 1%;
    margin: 2% 0 2% 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.TeacherFilter{
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    border-radius: 2vh;
    background-color: #95dc98;
    padding: 1% 2% 1% 2%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); 
}
#TPteacherDropdown,#TPcourseDropdown{
    margin: 0 2% 0 2%;
    width: 40vw;
    padding: 1%;
    color: gray;
    border: 0;
}
.TP-Details{
    width: 100%;
    height: auto;
    padding: 1%;
    display: flex;
    flex-direction: row;
    align-self: center;
}
.TPDetail-LeftSection{
    width: 50%;
    float: left;
}
.TPDetail-RightSection{
    width: 50%;
}

.TP-MeetSection,.TP-CurrentSchedule,.TP-Availability,.TP-BankDetails{
    width: 90%;
    height: auto;
    padding: 1%;
    margin: 2% 0 2% 0;
    background-color: #e0f2e1;
    border-radius: 1vh;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); 
}
.TP-CurrentSchedule{
    width: 50%;
}
.TP-Availability{
    width: 50%;
}
.TP-BankDetails{
    width: 50%;
}

.TP-Education,.TP-Experience,.TP-CoursesSection{
    width: 90%;
    height: auto;
    padding: 1%;
    margin: 2% 0 2% 0;
    background-color: #e0f2e1;
    border-radius: 1vh;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); 
}
.TP-Label{
    margin-right:1%;
    font-size:15px;
    color:white;
    padding:1%;
    font-weight:bold;
}