/* Container for the fee configuration form */
.fee-config-container {
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  background-color: #ffe6e6;
  text-align: center;
}
.form-flex-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two equal columns */
  gap: 10px; /* Space between fields */
  align-items: center;
  margin-bottom: 20px;
}

/* Heading styles */
.fee-config-heading {
  font-size: 32px; 
  font-weight: bold; 
  text-align: center;
  color: #007bff;
  margin-bottom: 20px;
}

/* Style for the input groups */
.fee-input-group label {
  text-align: left; /* Left-align label */
  font-size: 18px; /* Increased font size */
  margin-bottom: 2px;
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}



/* Input field styles */
.fee-input {
  padding: 4px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
}
.fee-select {
  padding: 6px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
}

/* Button styles */
.submit-button {
  grid-column: span 2; /* Span both columns */
  padding: 8px 16px; /* Adjusted size */
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-top: 1px;
  align-self: center;
  justify-self: center;
}

.submit-button:hover {
  background-color: #0056b3;
}

/* Table container */
.fee-table-container {
  margin: 30px auto;
  max-width: 800px;
  text-align: center;
}

/* Table styles */
.fee-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.fee-table th,
.fee-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: left;
  font-size: 14px;
}

.fee-table th {
  background-color: #007bff;
  color: #fff;
  font-weight: bold;
}

.fee-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.fee-table tbody tr:hover {
  background-color: #e9e9e9;
}

/* Icon button styles */
.icon-button {
  border: none;
  background: none;
  cursor: pointer;
  padding: 5px;
}

.icon-button:hover {
  color: #0056b3;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .fee-config-container {
    padding: 15px;
    grid-template-columns: 1fr; /* Single column for small screens */
  }

  .fee-input-group {
    width: 100%;
  }

  .fee-input,
  .fee-select {
    font-size: 12px;
  }

  .submit-button {
    font-size: 14px;
    grid-column: span 1; /* Reset button width on smaller screens */
  }

  .fee-table-container {
    width: 100%;
    overflow-x: auto;
  }

  .fee-table th,
  .fee-table td {
    padding: 10px 12px;
    font-size: 12px;
  }
}

.delete-btn {
  color: #f54f4f;
  cursor: pointer;
}

.delete-btn:hover {
  color: red;
}

.edit-button {
  color: #007bff;
  border: none;
  background: none;
  cursor: pointer;
}

.edit-button:hover {
  color: #0056b3;
}
