.ManageClassHeading
{
    background-color: #006480;
    width: auto;
    height: auto;
    flex-grow: 1;
    font-family: "Open Sans";
    font-size: 50px;
    font-weight: 300;
    color: rgb(255, 255, 255);
    line-height: 1.3;
    border-radius: 0px;
    text-align: center;
}
.ManageClassesFormDiv
{
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;
    padding: 2%;
    margin: 2% 10% 0 10%;
    background-color: #fcf2f2;
}
.ManageClassCreateButton,.AssignTeacherButton
{
    padding:  2% 4% 2% 4% ;
    border: 0;
    border-radius: 1vh;
    background-color: #03b4c6;
    color: white;
    font-size: 3vh;
}
.ManageClassesTable{
        width: 100%;
        height: auto;
        margin: 0;
}
.AssignTeacherButton
{
    padding:  1% 2% 1% 2% ;
    font-size: 2vh;
    margin: 1vh  0  1vh  1vh;
}
.ManageClassDeleteIcon
{
    margin:0 3vh 0 0;
    cursor:pointer;
}
.ManageClassDeleteIcon:hover{
    color: red;
}
.ManageClassDropdown
{
    padding: 1%;
    border: 1px solid rgb(217, 212, 212);
}
.ManageClassDropdown option,.ManageClassesFilter option
{
    color: gray;
}
.ManageClassesFilter
{
    display: flex;
    align-items: center;
    justify-content: center;
}
.ManageClassesFilter select
{
    padding: 1%;
    border: 1px solid rgb(217, 212, 212);
}
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dialog-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  min-width: 300px;
  max-width: 500px;
  max-height: 80vh;
  overflow-y: auto;
}

.students-table {
  width: 100%;
  border-collapse: collapse;
  margin: 15px 0;
}

.students-table th,
.students-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.students-table th {
  background-color: #f5f5f5;
}

.dialog-content button {
  padding: 8px 16px;
  background-color: #03b4c6;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: right;
  margin-top: 10px;
}

.dialog-content button:hover {
  background-color: #028e9d;
}