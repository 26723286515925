.School{
    width: 100%;  
    /*height: auto;*/
    background-color: e0f7fa;
margin-bottom: 0;
padding-bottom: 0;
}
.innerVision
{
    width: 80%;
    margin-left: 15%;
    text-align: left;
}
.mainHeading
{
    font-family: 'VanillaMilk';
    font-size: 50px;
    color: #8b0194;
    text-align: center;
    z-index: 1;
    padding: 20px;
}

.para
{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size:20px; 
    text-align: left;
    color: #8b0194; 

    z-index: 1; 
}
.classesModePics{
    display:flex;
    flex-direction: row;
    margin-left:15%;
}
.roadMapPic1,.roadMapPic2{
    width:40%;
    height:90vh;
}
.roadMapPic2{
    margin-left: 5%;
}
.roadMapContactButton
{
    display: flex;
    align-items: center;
    justify-content: center;
}
@media only screen and (max-width: 1000px)
{
    .classesModePics{
        flex-direction: column;
        margin-left: 0;        
    }
    .roadMapPic1,.roadMapPic2{
        width: 100%;
        height: auto;
        margin-left: 0;
    }

}