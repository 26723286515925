/* .RegisterInCoursePreferences
{
    display: flex;
    width: 25%;
    height: auto;
    background-color: #34979c;
    border-radius: 2vh;
    margin-left: 10%;
    padding: 2%;
    margin-top: 3vh;
    float: left;
} */
/* .RegisterInCoursePreferencesDays,.RegisterInCoursePreferencesTimes
{
    /* display: flex;
    flex-direction: column; */
    
/*} 
.RegisterInCoursePreferencesTimes
{

}

.days
{
    float:left;
    margin-right:9vh;
    color:white;
}
.Times{
    color:white;
}
.DiscountCode
{
    display: flex;
    width: auto;
    height: auto;
    align-items: center;
    justify-content: center;
}
.DiscountInputCode
{
    padding: 1%;
    border: 1px solid rgb(219, 213, 213);
    border-radius: 1vh;
    margin: 0 10% 0 0;
}
.DiscountInputCode:hover
{
    border: 1px solid rgb(7, 39, 179);
}
.RegisterCourseButton
{
    padding: 1% 2% 1% 2%;
    
}
.PaymentMessage
{
    margin: 3% 0% 0 20%;
}*/
.register-course-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .course-registration-info {
    max-width: 60%;
    text-align: center;
    margin-bottom: 20px;
    color: rgb(52, 151, 156);
    font-weight: bold; /* Make text bold */
  }
  
  .course-registration-info p {
    font-size: 1.1em;
    color: rgb(52, 151, 156);
    font-weight: bold; 
    text-align: center;
  }
  
  .course-registration-info ul {
    list-style-type: none;
    padding: 0;
  }
  
  .course-registration-info li {
    font-size: 1.1em;
    color: rgb(52, 151, 156);
    font-weight: bold; 
    margin: 5px 0;
    text-align: center;
  }
  
  .course-table {
    width: 80%;
    overflow-x: auto;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .course-table table {
    width: 100%;
    border-collapse: collapse;
    background-color: #e0f7fa;
  }
  
  .course-table th,
  .course-table td {
    padding: 12px 16px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  .course-table th {
    background-color: #008080;
    color: #fff;
    font-weight: bold;
  }
  
  .course-table td {
    color: rgb(52, 151, 156);
    font-weight: bold; 
  }
  
  .course-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .course-table td:last-child {
    width: 40%;
  }
  