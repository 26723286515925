@font-face{

    font-family: 'VanillaMilk';
    src: url('../fonts/VanillaMilk.otf');
}

.MathPortalBackground{
    width: 100%;
    height:600px;
    display: flex;
    background:  url('./MathCourseDetailImages/portalBackground.png');
    background-size: cover; 
    position: relative;
}
.overlay
{
  width: 100%;
  height: 100%;
  
  position: absolute;
}
 

.PageHead
{
    font-family: 'VanillaMilk';
    font-size: 60px;
    color: white;
    text-align: center;
    z-index: 1;
    padding: 20px;
}

.PageDesc
{
    
    font-family: 'DM Sans', sans-serif;
    text-decoration-color: white;
    text-align: left;
    padding: 30px;
    font-size: 22px;
    z-index: 1;
}

.section
{
    align-items: center;
    justify-content: space-evenly;
    display: flex;
    margin: auto;
    max-width: 90%;
    flex-direction: column;
}
.course-structure,.fee-structure{
    background-color: #d8f2fb;
   max-width:  100%;
}
.compass-image{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: url(./MathCourseDetailImages/Compass.png); */
    background-size: cover;
    position: relative;
    margin: 2%;
}
.modules
{
    margin-top: 2vh;
}
.small-box{
    border: 2px solid ;
    width: 30vh;
    height:10vh;
    text-align: center;
    background-color: #fad5f1;
    font-size: 15px;
    font-weight: bold;
   margin:3vh 1vh 0 0;
    float: left;
    border-radius: 5px;
    position: relative;
}
 .sm-box
 {
    margin-left: -2px;
 }
.large-box{
    border: 2px solid ;
    width: 100vh;
    height: 28vh;
    text-align: center;
    background-color: #fad5f1;
    font-size: 20px;
    font-weight: bold;
    float: left;
    margin: 0px  3px 3px 3px;
    border-radius: 5px;
}
.round-box{
    border: 4px solid #fad5f1;
    width: 20vh;
    height:20vh;
    text-align: center;
    background-color: #d67fb2;
    font-size: 16px;
    font-weight: bolder;
    float: left;
    border-radius: 10vh;
    position: relative;
}
.smallbox{
    border: 2px solid ;
    width: 30vh;
    height:10vh;
    text-align: center;
    background-color: #fad5f1;
    font-size: 15px;
    font-weight: bold;
    margin:3vh 1vh 0 0;
    float: left;
    border-radius: 5px;
    position: relative;
}
.largebox{
    border: 2px solid ;
    width: 100vh;
    height: 28vh;
    text-align: center;
    background-color: #fad5f1;
    font-size: 20px;
    font-weight: bold;
    float: left;
    margin: 0px  3px 3px 3px;
    border-radius: 5px;
}
.roundbox{
    border: 4px solid #fad5f1;
    width: 20vh;
    height:20vh;
    text-align: center;
    background-color: #d67fb2;
    font-size: 16px;
    font-weight: bolder;
    float: left;
    border-radius: 10vh;
    position: relative;
}

.roundbox1{
    border: 4px solid #c7cff5;

background-color: #5e6fab;
}
.smallbox1{
    
    background-color: #c7cff5;
    border: 2px solid #5e6fab;

}
.largebox1{
    background-color: #c7cff5;
    border: 2px solid #5e6fab;

}


.roundbox2{
    background-color:#eca222 ;
    border: 2px solid #faf0d0;
}
.smallbox2{
    
    background-color: #faf0d0;
    border: 2px solid #eca222;

}
.largebox2{
    background-color: #faf0d0;
    border: 2px solid #eca222;

}
 

/* Create three columns of equal width */
.columns {
    float: left;
    width: 33.3%;
    padding: 8px;
  }
  
  /* Style the list */
  .price {
    list-style-type: none;
    border: 1px solid #eee;
    margin: 0;
    padding: 0;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
  
  /* Add shadows on hover */
  .price:hover {
    box-shadow: 0 8px 12px 0 rgba(0,0,0,0.2)
  }
  
  /* Pricing header */
  .price .header {
    background-color: #249935;
    color: white;
    font-size: 25px;
  }
  
  /* List items */
  .price li {
    border-bottom: 1px solid rgb(255, 255, 255);
    padding: 20px;
    text-align: center;
  }
  
  /* Grey list item */
  .price .grey {
    background-color: #cff7d5;
    font-size: 20px;
  }
  
  /* The "Sign Up" button */
  .button {
    background-color: #03b4c6;
    border: none;
    color: white;
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
  }
  .b-img1{
    background-image: url('./MathCourseDetailImages/benefit1.png'); 
}.b-img2{
    background-image: url('./MathCourseDetailImages/benefit2.png'); 
}.b-img3{
    background-image: url('./MathCourseDetailImages/benefit3.png'); 
}.b-img4{
    background-image: url('./MathCourseDetailImages/benefit4.png'); 
}.b-img5{
    background-image: url('./MathCourseDetailImages/benefit5.png'); 
}.b-img6{
    background-image: url('./MathCourseDetailImages/benefit6.png'); 
}.b-img7{
    background-image: url('./MathCourseDetailImages/benefit7.png'); 
}.b-img8{
    background-image: url('./MathCourseDetailImages/benefit8.png'); 
}.b-img9{
    background-image: url('./MathCourseDetailImages/benefit9.png'); 
}.b-img10{
    background-image: url('./MathCourseDetailImages/benefit10.png'); 
}

.benefit-img{
    width: 50vh;
    height: 20vh; 

    background-size: cover;
    background-position: center;
    position: relative;
    background-repeat:no-repeat ;
}
.benefit-title{
    font-size: medium;
    color: #42869a;
    margin-left: 5rem;
    word-break: break-word;
    width: 25vh;
}
.benefit-detail-background{
    width: 100%;
    height: 100%;
    background-image: url('./MathCourseDetailImages/benefitDetail.png'); 
    background-size: cover;
    background-position: center;
    position: relative;
    background-repeat:no-repeat ;
}
.highlightTitleSection{
    padding-top: 3em;
    margin-left: 3em;
}
.benefit-resp-text
{
    margin-left: 0vh;
    width: 30vh;
    font-size: 15px;
    color: rgb(0, 169, 201);
}
.imgbene-resp{
    /* width: 355px; */
        height: -webkit-fill-available;
}
.wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}
.accordian{
    width: 130vh;
}
.item{
    background: white;
    margin-bottom: 5px;
}
.title{
    width: 285px;
    height: 90px;
    color:green ;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.content{
    /* color: #42869a; */
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0,1,0,1);
}
.content.show{
    /* max-width: "150vh"; background: "white"; margin-bottom: "5px"; border: '1px solid'; */
    height: auto;
    max-height: 9999px; 
    transition: all 0.5s cubic-bezier(0,1,0,1);

}
.span{
    margin-left: 50vh;
}
.fa-angle-down,.fa-angle-up{
    /* color: #5b1b05; */
    font-size: 8vh;
    float: right;
    /* display: flex;
    align-items: flex-end;
    justify-content: end; */
    /* margin-left: 40vh; */
}

.benefit-img{
    width: 285px;
    height: 90px;
    color:green ;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    position: relative;
    background-repeat:no-repeat ; 
    
    margin-top: 10px;
}

.gfg {
    /* margin-left:10vh ; */
    /* #5b1b05ed; */
    /* margin: 3%; */
    width: 150vh;
    height: 20vh;
    position: relative;
     
    background-color: rgb(255, 255, 255);
}

.first-txt {
    position: absolute;
    top: 17px;
    left: 50px;
}

.second-txt {
    position: absolute;
    bottom: 20px;
    left: 25vh;

}
.div-center{
    display: flex;
    align-items: center;
    justify-content: center;
}

.h-img1{
    background-image: url('./MathCourseDetailImages/highlight1.png'); 
}.h-img2{
    background-image: url('./MathCourseDetailImages/highlight2.png'); 
}.h-img3{
    background-image: url('./MathCourseDetailImages/highlight3.png'); 
}.h-img4{
    background-image: url('./MathCourseDetailImages/highlight4.png'); 
}.h-img5{
    background-image: url('./MathCourseDetailImages/highlight5.png'); 
}.h-img6{
    background-image: url('./MathCourseDetailImages/highlight6.png'); 
}.h-img7{
    background-image: url('./MathCourseDetailImages/highlight7.png'); 
}.h-img8{
    background-image: url('./MathCourseDetailImages/highlight8.png'); 
}.h-img9{
    background-image: url('./MathCourseDetailImages/highlight9.png'); 
}.h-img10{
    background-image: url('./MathCourseDetailImages/highlight10.png'); 
}.h-img11{
    background-image: url('./MathCourseDetailImages/highlight11.png'); 
}.h-img12{
    background-image: url('./MathCourseDetailImages/highlight12.png'); 
}

.highlight-img{
    width: 178px;
    height: 319px; 

    background-size: cover;
    background-position: center;
    position: relative;
    background-repeat:no-repeat ;
}
.highlight-title{
    font-size: medium;
    color: #42869a;
    margin-top: 10rem;
    margin-left: 1rem;
        border-bottom-color: #424242;
    word-break: break-word;
}
.gfg-highlights {
   
    width: 150vh;
    height: 20vh;
    position: relative;
     
    background-color:transparent;
}
a:link { text-decoration: none; }
a:hover {color: white;}
.btn-row{
    width: 100%;
    height: 5em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2em;
}
.btn-color-course{
    background-color: #e683e4;
}
.btn-color-portal{
    background-color: #e2e872;}
.btn-color-fee{
    background-color: #f09c60;}
.btn-color-Testimonal{
    background-color: #dbaf1f;
}.btn-color-Faq{
    background-color: #688cd9;
}.btn-color-registered{
    background-color: #37b33e;
}
html {
    scroll-behavior: smooth;
  }
.button{
  
    padding: 20px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    border: none;
    font-family: sans-serif;
}
