
@media only screen and (max-width: 1000px)
{
    .colsm{
        /* width: auto;
        height: auto;
        display: block; */
    /* min-width: 0; */
    flex-basis: 0;
    }
    .PageDesc
    {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: white;
    }
    .btn-row-resp{
        display: block;
        flex-direction: column;
        width: 200px;
        height: auto;
        align-items: center;
        justify-content: center;
        /* margin-left: 10%;
        margin-top: 5em; */
    }
    .compass-image{
        width: 100%;
        height: auto;
    }
    .compass-image img{
        width: 100%;
        height: auto;
    }
    .benefit-detail-background-resp{
        height: 100%;
    }
    .benefit-resp2{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 10vh;
        width:90%;
        float: right;
    } 
    .benefit-resp-text{
        left: 10vh;
        margin-left: 0vh;
        width: 19vh;
        font-size: 15px;
        color: rgb(114, 199, 82);
        text-align: center;
        font-weight: bolder;
    }
    .imgbene-resp{
        /* width: 90%;  */
        display: inline-flex;
        width: 92%;
        height: -webkit-fill-available;
    }
    .benefit-resp{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width:90%;
        float: right;
    }
    .arrow-resp{
        margin-left: 0vh;
    }
    .respon{
        /* flex-direction: column;
        align-items: center;
        justify-content: center; */

    }
    .section
    {
        flex-direction: column;
    }
    .course-structure{
        flex-direction: column;
    }
    .MathPortalBackground{

        height: auto;
    }
  
    .modules{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .small-box{
        /* display: flex;
        flex-direction: column; */
        margin-top: 5vh;
    }
    .large-box{
        
        display: flex;
        flex-direction: column;
        margin-top: 15vh;
        width: auto;
    }
    .section-left
    {
        flex-direction: column;
    }
    .Text-small
    {
        text-align: center;
        position: relative;
    }
    
    .Text-big
    {
        text-align: center;
    }
    .benefit-img{
        width: 100%;
        height: 15vh;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    .benefitLink{
        width: 85%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    .highlightTitleSection{
        margin-left: 1em;
    }
    .background{
        height: auto;
    padding-bottom: 10vh;
    }
    .arrowRespFont
    {
        font-size: xxx-large;
    }
    .col-md-2
    {
        width: 50%;
    }
  /* Change the width of the three columns to 100%
  (to stack horizontally on small screens) */

    .columns {
      width: 100%;
    }
    .IntroDesc
    {
        width: 75%;
    }
    .IntroImages img{
        width: 50vw;
        height: 40vw;
    }
    .IntroGif{
        width: 100vw !important;
        height: 38vh !important;
    }

    .portalIntro
    {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-self: center;
    }
    .portalRespPara
    {
        width: 115%;
        margin-left: 0;
        padding-left: 0;
    }
    .portalRespBullets
    {
        margin: 0;
    }
    .teacherTestimonal
    {
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2vh;
    }
    .testimonalBox1,.testimonalBox2{
        width: 50%;
        margin: 1vh;
        height: 30vh;
    }
    .kidSchool
    {
        height: auto;
    }
    .CEOMsg p{
        width: 100%;
        /* margin-left:15%; */
        padding-left: 0;
    }
    .footerText{
        margin-top: 0;
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: justify;
    }
    .footerText p{
        color: gray;
    }
    .footerContent{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 2vh;
    }
    .footerLinksSection
    {
        margin-top: 5%;
        display: flex;
        flex-wrap: wrap;
        height: auto;
        width: 100%;
        padding-top: 8vh;
    }
    .footerLinks,.footerLinks1,.footerLinks2
    {
        margin-top: 10vh;
        margin-left: 3%;
        margin-right: 3%;
        width: 44%;
    }
}


