.Testimonal_Heading{
	color: #333;
	text-align: center;
	text-transform: uppercase;
	font-family: "Roboto", sans-serif;
	font-weight: bold;
	position: relative;
	margin: 30px 0 60px;
}

.col-center {
	margin: 0 auto;
	float: none !important;
}
.carousel {
	padding: 0 70px;
}
.carousel .carousel-item {
	color: #999;
	font-size: 14px;
	text-align: center;
	overflow: hidden;
	min-height: 290px;
}
.backgroundtest{ 
    width: 100%;
    height:500px; 
    background:  url('./CourseImages/backgroundTestimonal.png');
    background-size: cover; 
    position: relative;
}
.carousel .carousel-item .imge-box1 {
    background:  url('./CourseImages/Khawaja_Mujtba_Sahaf.jpeg');
 
    background-size: cover;
    background-position: center;
    position: relative;
    background-repeat:no-repeat ;
	width: 135px;
	height: 135px;
	margin: 0 auto;
	padding: 5px;
	border: 1px solid #ddd;
	border-radius: 50%;
}

.carousel .carousel-item .imge-box2 {
    background:  url('./CourseImages/Wahab_Omer.jpeg');
 
    background-size: cover;
    background-position: center;
    position: relative;
    background-repeat:no-repeat ;
	width: 135px;
	height: 135px;
	margin: 0 auto;
	padding: 5px;
	border: 1px solid #ddd;
	border-radius: 50%;
}
.carousel .carousel-item .imge-box3 {
    background:  url('./CourseImages/Elina_Kumar.jpeg');
 
    background-size: cover;
    background-position: center;
    position: relative;
    background-repeat:no-repeat ;
	width: 135px;
	height: 135px;
	margin: 0 auto;
	padding: 5px;
	border: 1px solid #ddd;
	border-radius: 50%;
}
.carousel .carousel-item .imge-box4 {
    background:  url('./CourseImages/Saadia_Aftab.jpeg');
 
    background-size: cover;
    background-position: center;
    position: relative;
    background-repeat:no-repeat ;
	width: 135px;
	height: 135px;
	margin: 0 auto;
	padding: 5px;
	border: 1px solid #ddd;
	border-radius: 50%;
}
.carousel .carousel-item .imge-box5 {
	background:  url('./CourseImages/Eman.jpeg');
    background-size: cover;
    background-position: center;
    position: relative;
    background-repeat:no-repeat ;
	width: 135px;
	height: 135px;
	margin: 0 auto;
	padding: 5px;
	border: 1px solid #ddd;
	border-radius: 50%;
}
.carousel .carousel-item .imge-box6 {
    
	background:  url('./CourseImages/MUSTAFA.jpeg');
    background-size: cover;
    background-position: center;
    position: relative;
    background-repeat:no-repeat ;
	width: 135px;
	height: 135px;
	margin: 0 auto;
	padding: 5px;
	border: 1px solid #ddd;
	border-radius: 50%;
}
.carousel .carousel-item .imge-box7 {
    
	background:  url('./CourseImages/Abdul_hadi.jpeg');
    background-size: cover;
    background-position: center;
    position: relative;
    background-repeat:no-repeat ;
	width: 135px;
	height: 135px;
	margin: 0 auto;
	padding: 5px;
	border: 1px solid #ddd;
	border-radius: 50%;
}
.carousel .carousel-item .imge-box8 {
    
	background:  url('./CourseImages/Minahil_Jahanzeb.jpeg');
    background-size: cover;
    background-position: center;
    position: relative;
    background-repeat:no-repeat ;
	width: 135px;
	height: 135px;
	margin: 0 auto;
	padding: 5px;
	border: 1px solid #ddd;
	border-radius: 50%;
}
.carousel .carousel-item .imge-box9 {
    
	background:  url('./CourseImages/Zahra_Umair.jpeg');
    background-size: cover;
    background-position: center;
    position: relative;
    background-repeat:no-repeat ;
	width: 135px;
	height: 135px;
	margin: 0 auto;
	padding: 5px;
	border: 1px solid #ddd;
	border-radius: 50%;
}
.carousel .carousel-item .imge-box10 {
    
	background:  url('./CourseImages/Amal.jpeg');
    background-size: cover;
    background-position: center;
    position: relative;
    background-repeat:no-repeat ;
	width: 135px;
	height: 135px;
	margin: 0 auto;
	padding: 5px;
	border: 1px solid #ddd;
	border-radius: 50%;
}
.carousel .carousel-item .imge-box11 {
    
	background:  url('./CourseImages/Haris.jpeg');
    background-size: cover;
    background-position: center;
    position: relative;
    background-repeat:no-repeat ;
	width: 135px;
	height: 135px;
	margin: 0 auto;
	padding: 5px;
	border: 1px solid #ddd;
	border-radius: 50%;
}
.carousel .carousel-item .imge-box12 {
    
	background:  url('./CourseImages/Carl_Adrianne N.jpg');
 
    background-size: cover;
    background-position: center;
    position: relative;
    background-repeat:no-repeat ;
	width: 135px;
	height: 135px;
	margin: 0 auto;
	padding: 5px;
	border: 1px solid #ddd;
	border-radius: 50%;
}
.carousel .carousel-item .imge-box13 {
    background:  url('./CourseImages/Philip.png');
 
    background-size: cover;
    background-position: center;
    position: relative;
    background-repeat:no-repeat ;
	width: 135px;
	height: 135px;
	margin: 0 auto;
	padding: 5px;
	border: 1px solid #ddd;
	border-radius: 50%;
}
.carousel .carousel-item .imge-box14 {
    background:  url('./CourseImages/Faith.png');
 
    background-size: cover;
    background-position: center;
    position: relative;
    background-repeat:no-repeat ;
	width: 135px;
	height: 135px;
	margin: 0 auto;
	padding: 5px;
	border: 1px solid #ddd;
	border-radius: 50%;
}
.carousel .carousel-item .imge-box15 {
    background:  url('./CourseImages/testimonalGamedev.png');
 
    background-size: cover;
    background-position: center;
    position: relative;
    background-repeat:no-repeat ;
	width: 135px;
	height: 135px;
	margin: 0 auto;
	padding: 5px;
	border: 1px solid #ddd;
	border-radius: 50%;
}
.carousel .img-box img {

	width: 100%;
	height: 100%;
	display: block;
	border-radius: 50%;
}
.carousel .testimonial {
	padding: 30px 0 10px;
}
.carousel .overview {	
	font-style: italic;
}
.carousel .overview b {
	text-transform: uppercase;
	color: #7AA641;
}
.carousel-control-prev, .carousel-control-next {
	width: 40px;
	height: 40px;
	margin-top: -20px;
	top: 50%;
	background: none;
}
.carousel-control-prev i, .carousel-control-next i {
	font-size: 68px;
	line-height: 42px;
	position: absolute;
	display: inline-block;
	color: rgba(0, 0, 0, 0.8);
	text-shadow: 0 3px 3px #e6e6e6, 0 0 0 #000;
}
.carousel-indicators {
	bottom: -40px;
}
.carousel-indicators li, .carousel-indicators li.active {
	width: 12px;
	height: 12px;
	margin: 1px 3px;
	border-radius: 50%;
	border: none;
}
.carousel-indicators li {	
	background: #999;
	border-color: transparent;
	box-shadow: inset 0 2px 1px rgba(0,0,0,0.2);
}
.carousel-indicators li.active {	
	background: #555;		
	box-shadow: inset 0 2px 1px rgba(0,0,0,0.2);
}

