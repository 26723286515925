.HeadingPDFcert,.coursenamePDFcert{
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: cursive;
}
.HeadingPDFcert{
    font-size: 7vh;
}

.StudentDetails
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.StudentTable {
    width: 40%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .StudentTable th, .StudentTable td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .StudentTable th {
    background-color: #f2f2f2;
  }