.AttendClassJoinClass
{
    background-color: #edffff;
    width: 100%;
    height: auto;
    text-align: center;
    height: 80vh;
}
.AttendClassRate
{
    width: 30%;
    height: auto;
    border-radius: 5vh;
    background-color: #bbf0fa;
    padding:3%;
    margin-left: 35%;
}
.AttendClassComment
{
    width: 50%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    resize: vertical;
}
.AttendClassSendRating
{
    background-color: #03b4c6;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 50%;
    margin-top: 1%;
}
.AttendClassBtn
{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #edffff;
    margin: 2%;

}
.AttendClassAttendInput
{
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    resize: vertical;
}
.AttendClassjoinBtn
{
    background-color: #bbf0fa;
    color: #03b4c6;
    padding: 12px 20px;
    border: none;
    border-radius: 2vh;
    font-size: 3vh;
    cursor: pointer;
    float: right;
    width: 25%;
    box-shadow: 0 3px 5px 0 rgba(100, 96, 96, 0.3); /* This creates a shadow effect */
    
}
.AttendClassjoinBtn:hover{
    background-color:#03b4c6 ;
    color: white;
}