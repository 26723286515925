.grade-project-page {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f0f9ff;
}

.grade-project-page h1 {
  text-align: center;
  color: #1e90ff;
  margin-bottom: 20px;
}

.student-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 0 auto;
  max-width: 900px;
}
.student-search-bar {
  width: 100%;
  max-width: 300px;
  padding: 8px;
  margin: 20px 80;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  align-items: center;
  margin-left: 600px;
}


.student-row {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #d4f1f9;
  padding: 15px;
  border-radius: 8px;
}

.student-name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.project-inputs {
  display: flex;
  gap: 10px;
  align-items: center;
}

.project-inputs select,
.project-inputs input[type="number"] {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.project-inputs a {
  text-decoration: none;
  color: #1e90ff;
  padding: 5px;
}

.project-inputs span {
  font-weight: bold;
}

button {
  background-color: #1e90ff;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

button:hover {
  background-color: #005bb5;
}
