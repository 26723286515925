.OTPInput{

    width: 50px;
    height: 8vh;
    text-align: center;
    font-size: medium;
    font-weight: bold;
    color: #03b4c6;
}

.OTPInput:hover{

    border:2px solid #03b4c6;

}