.AP_container
{
    background-color: white;
    /* border-radius: 2vh; */
    border: 0;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    display: flex;
    flex-direction: column;
   /* min-width: 80%; */
}
.AP_title
{
    /* color: #5665e9; */
    text-align: center;
    margin-bottom: 7%;
}
.AP_ModuleProjectLesson{
    /* background-color: #dae8ff; */
    border-radius: 2vh;
    padding: 1%;
    height: auto;
    width: 40%;
    float: left;
    margin: 1vh;
}
.AP_Projects
{
    list-style: none;

}

.AP_Icon,.AP_Score,.AP_Time
{
    /* background-color: #aec7f0; */
    border-radius: 7vh;
    border: 2px solid white;
    padding: 2%;
    margin-left: 2%;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    font-size: 2vh;
    font-weight: bold;
    width: 100%;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes fadeInOut {
  0% { opacity: 0; transform: translateY(-20px); }
  100% { opacity: 1; transform: translateY(0); }
}
