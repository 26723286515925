
.location-info {
    font-family: Arial, sans-serif;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    max-width: 500px;
    background-color: #f9f9f9;
  }
  
  .location-info h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  
  .location-info p {
    font-size: 1em;
    margin: 5px 0;
  }
  
  .vpn-warning {
    color: red;
    font-weight: bold;
  }
  
  .loading,
  .error {
    font-family: Arial, sans-serif;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    max-width: 500px;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .loading {
    color: #555;
  }
  
  .error {
    color: red;
  }
  