.School{
    width: 100%;  
    height: auto;
background-color: #e0f7fa;
}
.innerVision
{
    width: 70%;
    margin-left: 15%;
    text-align: left;
}
.mainHeading
{
    font-family: 'VanillaMilk';
    font-size: 50px;
    color: #8b0194;
    text-align: center;
    z-index: 1;
    padding: 20px;
}

.para
{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size:15px; 
    padding: 10px;
    text-align: left;
    color: #000000; 

    z-index: 1; 
}
@media only screen and (max-width: 1000px)
{
    .innerVision{
        width: -webkit-fill-available;
        margin-left: 5%;
    }    
    .para
    {
        padding: 0;
    }
}
