@font-face{

    font-family: 'VanillaMilk';
    src: url('../fonts/VanillaMilk.otf');
}

.CodingPortalBackground{
    width: 100%;
    height:600px;
    display: flex;
    background:  url('./CodingCourseImages/portalBackground.png');
    background-size: cover; 
    position: relative;
    
}
.individual-course-img{
    /* width: 25vh;
    height: 21vh;  */
/* width: 100%;
height: 100%; */
    background-size: cover;
    background-position: center;
    position: relative;
    background-repeat:no-repeat ;
}
.I-img1{
    background-image: url('./CodingCourseImages/scratch.png'); 
}.I-img2{
    background-image: url('./CodingCourseImages/appdev.png'); 
}.I-img3{
    background-image: url('./CodingCourseImages/webdev.png'); 
}.I-img4{
    background-image: url('./CodingCourseImages/gamedev.png'); 
}.I-img5{
    background-image: url('./CodingCourseImages/php.png'); 
}.I-img6{
    background-image: url('./CodingCourseImages/AI.svg'); 
}.I-img7{
    background-image: url('./CodingCourseImages/py.png'); 
}
.ind-course-title{
    font-size: medium;
    color: #8b0194;
    margin-left: 1rem;
  
}
.ind-course-border{
    border: 1px solid blue;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.CourseRoadMap
{
   background: url('CodingCourseImages/courseroadmapbg.jpeg');
   background-size: cover; 
    height: auto;
    width: 100%;
}
.CourseRoadMap div{
    display: flex;
    flex-direction: row; 
    align-items: center;
    justify-content: space-evenly;  
}
.CourseTabContent div
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    width: 25vw;
    height: 100vh;
    padding: 0px 5vh 0 5vh;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3); 

}
 .NoOfLesson
 {
    padding:1% 2% 1% 2%;
    font-size: lighter;
    color: white;
    border-radius: 1vh;
 }
 .RoadMapBullets
 {
    padding-top: 3vh;
    text-align: start;
    font-size: 2.4vh;
 }
.RequestCurriculumbtn
{
    color: white;
    font-size:medium;
    font-weight: bold;
    padding: 2% 4% 2% 4%;
    border-radius: 1vh;
}
.CourseRoadMapPara
{
    font-size: 2.4vh;
}

  #tb:hover {
    background-color: #d99fdd;
    font-weight: bold;
    color: #8b0194;
  }
#RoadMapTabs
{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 2%;
}
#RoadMapTabs div{
    /* background-color: #d99fdd; */
    border: 2px solid #8b0194;
    padding: 0.5% 1% 0.5% 1%;
    border-radius: 1vh;
    cursor: pointer;
}
#CourseTabContentDetails1
{
    height: 35%;
}
#CourseTabContentDetails2
{
    height: 65%;
}
@media only screen and (max-width: 1000px)
{
    .CodingPortalBackground{

        height: auto;
        width: auto;
    }
    .CourseRoadMap div
    {
        flex-direction: column;   
        padding: 1vh;
    }
    .CourseTabContent div
    {
        width: 100%;
        box-shadow: none;
        padding: 2%;
        height: -webkit-fill-available;
    }
    #RoadMapTabs {
        flex-direction: row;
    }
    #CourseTabContentDetails1,#CourseTabContentDetails2
    {
        height: -webkit-fill-available;
        width: -webkit-fill-available;
    }
}