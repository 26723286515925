.CorelPortalBackground{
    width: 100%;
    height:600px;
    display: flex;
    background:  url('./GraphicCourseImages/portalBackground.png');
    background-size: cover; 
    position: relative;

}
@media only screen and (max-width: 1000px)
{
    .CorelPortalBackground{

        height: auto;
        width: auto;
    }
}