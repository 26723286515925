/* General Layout */
.invoice-page {
  display: flex;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f0f2f5;
  /* padding: 20px; */
  border-radius: 10px;
  flex-direction: row;
}

/* Form Styling */
.invoice-form {
  width: 30%; /* Reduced form width */
  padding: 20px;
  background-color: rgb(230, 241, 247);
  /* border-radius: 10px; */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  /* margin-right: 20px; */
  display: flex;
  flex-direction: column;
}

/* Fee Box Layouts */
.fee-section {
  margin-bottom: 10px; /* Less margin between sections */
  /* padding: 10px; */
  /* border-radius: 5px;  */
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); */
  /* background-color: #e8e8e8; */
  width: 100%; /* Reduced width for fee sections */
}
.abacus-fee{
  background-color: rgb(192, 237, 191);
}
.outstanding-fee{
  background-color: rgb(237, 204, 191);
}
.delivery-fee{
  background-color:rgb(191, 210, 237)
}
.bank-details{
  background-color:rgb(191, 237, 237)
}

.monthly-fee, .membership-fee, .abacus-fee, .outstanding-fee,.delivery-fee, .bank-details {
  margin-bottom: 8px; /* Less margin between these sections */
  padding: 10px;
  border-radius: 10px;
  width: 100%;
}
.monthly-fee{
  background-color: rgb(216, 189, 237);
  padding: 10px;
  border-radius: 10px;
  width: 100%;
}
/* Date Input Layout */
.date-inputs {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.date-inputs .form-group {
  flex: 1 1 45%; /* Keep columns aligned */
}

/* Adjust Form Group Layout */
.form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

label {
  font-size: 14px; /* Reduced label font size */
  color: #333;
  margin-bottom: 5px;
}

/* Inputs and Dropdowns */
.invoice-page input[type="text"],
.invoice-page input[type="number"],
.invoice-page input[type="date"],
.invoice-page textarea,
.invoice-page select {
  width: 100%; 
  padding: 8px;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  font-size: 14px; /* Reduced font size */
  color: #333;
}

textarea {
  height: 70px; /* Reduced height of text area */
}

select {
  background-color: #fafafa;
}

/* Buttons */
button[type="submit"] {
  width: 70%; /* Decreased button width */
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 14px; /* Reduced font size */
  cursor: pointer;
  transition: background-color 0.3s;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

/* Invoice Display */
.invoice-display {
  width: 75%; /* Reduced display panel width */
  padding: 25px;
  background-color: #ffffff;
  /* border-radius: 10px; */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}
.invoiceLink:hover{
  color: #333;
}
.invoice-list-display {
  width: 70%; /* Reduced display panel width */
  padding: 25px;
  background-color: #ffffff;
  /* border-radius: 10px; */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.invoice-display h2, .invoice-list-display h2{
  /* font-size: 20px; Reduced heading size */
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 20px;
  color: rgb(59, 59, 59);
  font-family: Barlow;
  font-size: 36px;


    min-height: 45px;
    height: max-content;
    flex-grow: 0;
    flex-shrink: 0

}

.invoice-display p, 
.invoice-display table,
.invoice-list-display p,
.invoice-list-display table {
  margin-bottom: 10px;
  font-size: 15px; /* Slightly smaller text */
  color: #333;
}
.billed p{
  margin-bottom: 0;
  font-size: 16px;

}
.billed {
  margin-bottom: 20px;
}

.unpaid{
  font-weight: bold;
  color: red;

}
.invoice-display table,
.invoice-list-display table
{
  width: 100%;
  border-collapse: collapse;
}

.invoice-display table th,
.invoice-list-display table th,
.invoice-display table td,
.invoice-list-display table td
 {
  /* border: 1px solid #d1d1d1; */
  padding: 10px;
  text-align: left;
}

.invoice-display table th,
.invoice-list-display table  th {
  background-color: rgb(230, 230, 232);
  color: black;
}

.invoice-display table td,
.invoice-list-display table td {
  background-color: #f9f9f9;
}

.invoice-display ul,
.invoice-list-display ul {
  list-style-type: none;
  padding-left: 0;
}

.invoice-display ul li,
.invoice-list-display ul li {
  margin-bottom: 10px;
}

.invoice-display p.subtotal {
  font-weight: bold;
  font-size: 18px;
  color: #333;
}
.invoice-display .total {
  font-weight: bold;
  font-size: 20px;
  color: #333;
}
.grayedColor {
  font-weight:500;
  font-size: 16px;
  color: #333;
}
.membership-fee{
  background-color: rgb(237, 236, 191);
  padding: 10px;
  border-radius: 10px;
  width: 100%;
}
.invoice-header{
  display: flex;
  justify-content: space-between;
  align-items: end;
}
.invoice-header p{
  font-size: 16px;
  font-weight: bolder;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .invoice-page {
    flex-direction: column;
  }

  .invoice-form,
  .invoice-display {
    width: 100%;
    margin-right: 0;
  }

  .fee-section .form-group {
    flex-direction: column;
    align-items: flex-start;
  }

  .fee-section input {
    width: 100%;
    margin-top: 5px;
  }
}
