.main{
    height: 70vh;
    width: 30%;
    position: absolute;
    margin: 1% 0% 0 0%;
    left: 40%;   
}
.resetlink{
  color: #03b4c6;
  margin-left: '30%';
  cursor:'pointer'; 
}

.resetlink:hover{
color: black;
}
.inp
{
    width: 90%;
    height: auto;
    border: 1px solid rgb(175, 175, 175);
    border-radius: 1vh;
    margin: 2%;
    padding: 3%;
}
.inp:hover{
    border: 2px solid #03b4c6;

}
.btn
{
  width:20vw;
    padding: 3% 6% 3% 6%;
    background-color: white;
    font-weight: bold;
    color: #03b4c6;
    font-family: sans-serif;
    border: 2px solid #03b4c6;
    border-radius: 1vh;
    margin: 3% 0 0% 10%;
    text-align: center;
    word-wrap: break-word;
    
}
.btn:hover{
    background-color: #03b4c6;
    color:white ;
}

/* Admin NavBar */
.UserDropdown
{
    font-size: 20px;
    color: #03b4c6;
}
.UserDropdown:hover
{
    color: white !important;
    background-color:#03b4c6;
}
 
   .dropdown-container {
    position: relative;
    display: inline-block;
    /* background-color: #03b4c6;
    border: 1px solid #2f3030; */
  }
    
  .dropdown-menu_ {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 999;
    background-color: #edffff;
    border: 1px solid #03b4c6;
    /* Your styles for the dropdown menu */
  }
  
  .course-text {
    display: block;
    white-space: normal;
    overflow-wrap: break-word;
  }
  
  .overlay1122,.overlay1133 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent dark background */
    z-index: 9998;
  }
  
  
  .popup{
    height: 70vh;
    width: 100%;
    background-color: rgb(255, 255, 255);
    position: absolute; 
    overflow-x:inherit;
    overflow-y:auto;
    padding-left: 5%;
    border-radius: 2vh;
    /* transition: 2s ease;
     */
}
.popup-header{
    display: flex;
    justify-content: space-between;
    padding: 0 30px 0 15px; 
}

.SignUP-avatar-upload {
  display: flex;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #e0e0e0;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.SignUP-avatar-upload img {
  max-width: 100%;
  max-height: 100%;
}
.Signupbtn
{
  border: 0;
  border-radius: 0;
  
}
 

.Navbar-Right-Aligned
{
  justify-content: flex-end;
  /* padding-right: 3%; */
}
.user-profile-dropdown
{
  border:1px  solid #03b4c6;
  background-color:#edffff;
  margin-left: -80%;
}
.pageLogo
{
   width: 15%; height: 3%;
}
@media only screen and (max-width: 1000px)
{
  .pageLogo{
    width: 30%;
  }
  .main
  {     
      margin-left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 95%;
      left: 0;
      margin-left: 2%;
  }
  .popup
  {
    padding-left: 0;
    margin-left: 1%;
    margin-right: 1%;
  }
  .btn{
    width: 80%;
  }
  .user-profile-dropdown
  {
    width: 80%;
    margin: 0;
  }
}




.modal-content{
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.AttendClassButton{

  background-color: #03b4c6;
  color: white;
  font-weight: normal;
  border: none;
  border-radius: 3vh;
  width: 50%;
  padding: 2vh;
  font-size: large;
}
.AttendClassDropdown
{
  width: 50%;
  height: auto;
  padding: 2vh;
  border: none;
  margin: 2vh;

  
}
.AttendClassDropdown option {
  max-width: 100%; /* Ensures options don't exceed dropdown's width */
  white-space: nowrap; /* Prevents text wrapping */
  overflow: hidden; /* Hides overflowing text */
  text-overflow: ellipsis; /* Adds ellipsis (...) for overflowing text */
  display: inline-block; /* Necessary for text-overflow to work */
}
.dropdown-menu-scrollable {
  max-height: 300px;
  overflow-y: auto;
}
