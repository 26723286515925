*{
    padding: 0;
    margin: 0;
}

.MAMCQheading{
    margin: 0 5vw 0 5vw;
    display: flex;
    align-items: center;
}

.MADescMargin{
    margin-left: 20vw;
}

.MAMCQactivity-image{
    width: 70px;
    margin-right: 20px;
}

.MAMCQactivity-image img{
    width: 100%;
}

.MAMCQactivity-text{
    color: rgb(227, 135, 35);
    font-size: 20px;
}

.MAMCQcontainer{
    margin: 0 5vw 0 5vw;
}

.MAMCQhead{
    display: flex;
}

.MAMCQquestions{
    flex: 5;
    background-color: rgb(227, 135, 35);
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: aliceblue;
    border-radius: 10px 0 0 10px;
    
}


#total-questions{
    margin-left: 2px;
}

.MAMCQprogress-bar{
    height: 5px;
    width: 130px;
    background-color: rgb(154, 168, 168);
    border-radius: 5px;
    margin-top: 5px;
}

#progress{
    height: 5px;
    width: 0;
    background-color: aqua;
    border-radius: 5px;
}

.MAMCQscore{
    flex: 1;
    padding-top: 3px;
    display: flex;
    flex-direction: column;
    background-color: rgb(64, 163, 64);
    color: white;
    text-align: center;
    border-radius: 0px 10px 10px 0;
    justify-content: space-between;
}

.MAMCQnumbered-score{
    background-color: rgb(158, 196, 158);
    border-radius: 15px;
    padding: 10px;

}

.MADescbody{
    background-color: rgb(250, 240, 214);
    display: flex;
    border-radius: 15px 0 0 15px;
    
}

.MADescremaining-body{
    background-color: rgb(250, 240, 214);
    flex: 5;
    padding: 20px;
    display: grid;
    grid-template-columns: 4fr 3fr 1fr;
    column-gap: 10px;
    row-gap: 20px;

    border-radius: 15px 0 0 15px;

    
}

.MADescquestion{
    border-width: 2px;
    border-color: rgb(227, 135, 35);
    border-style: solid;
    text-align: center;
    color: rgb(227, 135, 35);
    font-size: 30px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 0.3fr;
    max-height: 380px;
    width: 80vw;
}


.MAMCQmental-question-section{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.MAMCQmcq-text{
    text-align: left;
    margin-left: 20px;
}

.MAMCQmcq-options{
    text-align: left;
    margin-left: 20px;
}
.MAMCQmental-question-section h4{
    margin-top: 15px;
    color: black;

}

#MAMCQmental-question-text{
    font-size: 30px;
    color: rgb(227, 135, 35);
}

.MAMCQmental-question-section img{
    width: 30px;
    margin-right: 5px;
}

.MAMCQplay-question{
    background-color: rgb(250, 240, 214);
    border: none;
    cursor: pointer;
}

.MAMCQplay-question img{
    flex: 1;
    background-color: rgb(250, 240, 214);
    border: none;
    cursor: pointer;
}

.MAMCQplay-question img:active{
    width: 90px;
}

.MAMCQpicture img{
    width: 300px;
}


.MAMCQtime{
    display: flex;
    border: 2px solid red;
    height: 40px;
    border-radius: 20px;
    margin-bottom: 10px;
}

.MAMCQtime-text{
    background-color: red;
    color: white;
    border-radius: 18px;
    display: flex;
    align-items: center;
    padding: 15px;
}

.MAMCQtimer{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 15px;
}

.MAMCQmarks{
    display: flex;
    border: 2px solid rgb(170, 96, 0);;
    height: 40px;
    border-radius: 20px;
    margin-bottom: 10px;
}

.MAMCQmarks-text{
    background-color: rgb(170, 96, 0);
    color: white;
    border-radius: 18px;
    display: flex;
    align-items: center;
    padding: 15px;
}

.MAMCQmarker{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 15px;
}

.MAMCQinstructions-dropdown{
    background-color: rgb(250, 240, 214);
    color: rgb(227, 135, 35);
    padding: 15px;
    font-size: 15px;
}

.MAMCQanswer-submit input::placeholder{
    font-size: 15px;
}

.MAMCQanswer{
    border: none;
    border-radius: 8px;
    padding: 15px 10px;
}

#MAMCQsubmission{
    border: none;
    background-color: rgb(18, 18, 192);
    border-radius: 8px;
    color: white;
    padding: 15px 15px;
    cursor: pointer;
}

#marks-color{
    flex: 1;
    background-color: rgb(254, 236, 242);
    border: 1px solid rgb(227, 135, 35);
    border-radius: 0 10px 10px 0;
    overflow: scroll;
    max-height: 300px;
}

.MAMCQbox{
    display: flex;
    border: 2px solid rgb(227, 135, 35);
    margin: 0 10px 2px 10px;
}

.MAMCQbox1{
    flex: 1;
    border-width: 0 2px 0 0;
    border-color: rgb(227, 135, 35);
    border-style: solid;
    text-align: center;
}
.MAMCQbox2{
    flex: 1;
    background-color: red;
    
}

.MAMCQremarks{
    margin: 0 5vw 0 5vw;
    display: flex;
    justify-content: space-between;
}

.MAMCQsubmitButton{
    border: none;
    background-color: rgb(18, 18, 192);
    border-radius: 8px;
    color: white;
    padding: 10px 10px;
    cursor: pointer;
    margin-bottom: 5px;
}
.MAMCQsubmitButton h5{
    font-size: 16px;
}

.MADesccustom-file-upload{
    margin-top: 50px;
    color: black;
}


.MADesfile-upload{
    margin-top: 50px;
    color: black;
}
