.IslamicKnowledge-Background
{
    width: 100%;
    height:600px;
    display: flex;
    background:  url('./QuranCourseImages/portalBackground.png');
    background-size: cover; 
    position: relative;
}
.moduleSection{
    color: rgb(36, 153, 53);
    margin-top: 3vh;
    font-size: 2em;
    font-family: VanillaMilk;
}
@media only screen and (max-width: 1000px)
{
    .IslamicKnowledge-Background
    {
        height: auto;
        width: auto;
    }
    .moduleSection{
        flex-direction: column;
        display: flex;
        align-items: center;
        text-align: center;
    }
}