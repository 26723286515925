.add-question-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 400px;
    max-height: 500px;
    overflow-y: auto;
    border-radius: 30px;
}

.edit-question-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #42cf1f;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 400px;
    max-height: 500px;
    overflow-y: auto;
    border-radius: 30px;
    z-index: 1001; /* Ensure the modal is above other elements */
  }



.maintain-overflow-question{
    overflow-y: auto;
}

.add-question-dialog::-webkit-scrollbar {
    width: 12px;
}

.add-question-dialog::-webkit-scrollbar-thumb {
    background-color: skyblue;
    border-radius: 30px;
}

.add-question-dialog::-webkit-scrollbar-track {
    background-color: #f5f5f5;
}

.form-field {
    margin-bottom: 20px;
}

.label {
    margin-bottom: 8px;
}

.input-question {
    padding: 8px;
    margin-bottom: 16px;
}

.button-group {
    display: flex;
    justify-content: space-between;
}

.cancel-button {
    padding: 10px;
    cursor: pointer;
    background-color: #ccc;
    border: none;
    border-radius: 4px;
}

.inz-dropdown{
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.inz-title{
    text-align: center;
    background-color: rgb(102, 247, 247);
    color: black;
    border-radius: 30px;
    padding: 10px;
}
