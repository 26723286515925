.create-course-container {
  margin: 50px auto;
  max-width: 600px;
  background-color: #fff3cd;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}


.create-course-container h1 {
  text-align: center;
}

.create-course-container form {
  margin-top: 20px;
}

.create-course-container select {
  width: 100%;
}

@media (max-width: 576px) {
  .create-course-container {
    margin: 20px;
    padding: 10px;
    max-width: 100%;
  }
}

.create-course-module {
  background-color: #a56dff;
  margin: 50px auto;
  max-width: 600px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.create-course-module h2 {
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

.create-course-module .form-group {
  margin-bottom: 20px;
}

.create-course-module label {
  color: white;
}

.create-course-module input {
  border-radius: 10px;
  border: none;
  padding: 10px;
  width: 100%;
  background-color: white;
}

.create-course-module select {
  border-radius: 10px;
  border: none;
  padding: 10px;
  width: 100%;
  background-color: white;
}


.create-lesson-activity {
  background-color: #ec88b7;
  margin: 50px auto;
  max-width: 600px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.create-lesson-activity h2 {
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

.create-lesson-activity .form-group {
  margin-bottom: 20px;
}

.create-lesson-activity label {
  color: white;
}

.create-lesson-activity input {
  border-radius: 10px;
  border: none;
  padding: 10px;
  width: 100%;
  background-color: white;
}

.create-lesson-activity select {
  border-radius: 10px;
  border: none;
  padding: 10px;
  width: 100%;
  background-color: white;
}



.create-lesson-plan {
  background-color: rgb(114, 176, 235);
  margin: 50px auto;
  max-width: 600px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.create-lesson-plan h2 {
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

.create-lesson-plan .form-group {
  margin-bottom: 20px;
}

.create-lesson-plan label {
  color: white;
}

.create-lesson-plan input {
  border-radius: 10px;
  border: none;
  padding: 10px;
  width: 100%;
  background-color: white;
}

.create-lesson-plan select {
  border-radius: 10px;
  border: none;
  padding: 10px;
  width: 100%;
  background-color: white;
}


.create-lesson-project {
  background-color: #fdee98;
  margin: 50px auto;
  max-width: 600px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.create-lesson-project h2 {
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

.create-lesson-project .form-group {
  margin-bottom: 20px;
}

.create-lesson-project label {
  color: white;
}

.create-lesson-project input {
  border-radius: 10px;
  border: none;
  padding: 10px;
  width: 100%;
  background-color: white;
}

.create-lesson-project select {
  border-radius: 10px;
  border: none;
  padding: 10px;
  width: 100%;
  background-color: white;
}


.create-module-lesson {
  background-color: #aae797;
  margin: 50px auto;
  max-width: 600px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.create-module-lesson h2 {
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

.create-module-lesson .form-group {
  margin-bottom: 20px;
}

.create-module-lesson label {
  color: white;
}

.create-module-lesson input {
  border-radius: 10px;
  border: none;
  padding: 10px;
  width: 100%;
  background-color: white;
}

.create-module-lesson select {
  border-radius: 10px;
  border: none;
  padding: 10px;
  width: 100%;
  background-color: white;
}


.create-module-project {
  background-color: #fd98b6;
  margin: 50px auto;
  max-width: 600px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.create-module-project h2 {
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

.create-module-project .form-group {
  margin-bottom: 20px;
}

.create-module-project label {
  color: white;
}

.create-module-project input {
  border-radius: 10px;
  border: none;
  padding: 10px;
  width: 100%;
  background-color: white;
}

.create-module-project select {
  border-radius: 10px;
  border: none;
  padding: 10px;
  width: 100%;
  background-color: white;
}

.create-lesson-activity .twobutton {
  margin-top: 20px;
  padding-top: 10px;
}

.modalStyle {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 400px;
  max-height: 500px;
  overflow-y: auto;
  border-radius: 30px;
}

.my-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 3%;
}

.save-button {
  max-width: 15vw;
  padding: 3% 6%;
  background-color: white;
  font-weight: bold;
  color: #03b4c6;
  font-family: sans-serif;
  border: 2px solid #03b4c6;
  border-radius: 1vh;
  margin: 1% 0;
  text-align: center;
  word-wrap: break-word;
}

@media (max-width: 768px) {
  .save-button {
    max-width: 30vw;
    padding: 5% 10%;
  }
}

@media (max-width: 480px) {
  .save-button {
    max-width: 50vw;
    padding: 7% 12%;
  }
}
