.lessonOption
{
    width: 70%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    resize: vertical;
}
.lessonPlan
{
    width: 30%;
    height: auto;
    border-radius: 5vh;
    background-color: #bbf0fa;
    padding:3%;
    margin-left: 2%;
}
.Inp
{
    width: 30%;
    height: auto;
    border: 0;
    border-radius: 1vh; 
    padding: 2%;
}
.sendBtn
{
    width: 30%;
    padding: 12px;
    background-color: #03b4c6;
    color: white;
    font-weight: bold;
    margin: 2%;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    resize: vertical;
}
.joinBtn
{
    border: 0;
    background-color:#bbf0fa ;
    color: #03b4c6;
    padding: 2vh 10vh 2vh 10vh;
    font-size: 4vh;
    border-radius: 2vh;
    margin: 2%;
}
.LessonActivities
{ 
    font-size: 3vh;
    word-wrap: break-word;
    cursor: pointer;
}
.LessonActivities:hover{
    color: #0c6b75;
}
.ActivitiesLogo
{
   width: 10%;
   height: auto;
   margin: 1vh;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes fadeInOut {
  0% { opacity: 0; transform: translateY(-20px); }
  100% { opacity: 1; transform: translateY(0); }
}