@font-face{

    font-family: 'VanillaMilk';
    src: url('../fonts/VanillaMilk.otf');
}

.BusinessPortalBackground{
    width: 100%;
    height:600px;
    display: flex;
    background:  url('./BusinessCourseImages/portalBackground.png');
    background-size: cover; 
    position: relative;
}
.I-img1{
    background-image: url('./BusinessCourseImages/kidpreneur.png'); 
}.I-img2{
    background-image: url('./BusinessCourseImages/digitalMarketing.png'); 
}.I-img3{
    background-image: url('./BusinessCourseImages/funFinance.png'); 
}.I-img4{
    background-image: url('./BusinessCourseImages/eCommerce.png'); 
}
@media only screen and (max-width: 1000px)
{
    .BusinessPortalBackground{

        height: auto;
        width: auto;
    }
}