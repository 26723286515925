.manage-lesson-plans {
    background-color: #ff9900;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
  }

  .manage-lesson-plans h2 {
    color: white;
  }

  .plan-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  .plan-card {
    background-color: white;
    border-radius: 4px;
    padding: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 300px;
  }

  .edit-form {
    margin-top: 20px;
  }

  .edit-form input,
  .edit-form textarea {
    display: block;
    margin-bottom: 10px;
    width: 100%;
  }

  .buttonsinz {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .aqua-button1 {
    background-color: rgb(54, 168, 168);
    size: 100px;
  }

  .update-button {
    background-color: lightblue;
    margin-right: 10px;
  }
