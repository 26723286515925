.SummerSection_1,.SummerSection_2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.SummerSection_btngrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}
.SummerSection_btn1,.SummerSection_btn2,.SummerSection_btn3,.SummerSection_btn4 {
    padding: 10px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
    margin: 2vh;
}
.SummerSection_btn1{
    background-color:#2fdb1f ;
}
.SummerSection_btn2{
    background-color:#9b68d9 ;
}
.SummerSection_btn3{
    background-color:#dbaf1f ;
}
.SummerSection_btn4{
    background-color:#688cd9 ;
}
.SummerSection_video{
    width: 100%;
    display: flex;
    justify-content: center;
}

.SummerCamp24{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.SummerCamp24 b{
    color: #8b0194;
    font-weight: bold;
    font-size: 8vh;
    font-family: cursive;
}
.SummerCamp24 p{
    color: #ddaf1f;
    font-weight: bold;
    width: 80%;
}

.Summer_countries{
    display: flex;
    align-items: center;
    justify-content: center;
}
.Summer_country{
    text-align: center;
    width: 15vw;
    height: 30vh;
    padding: 3%;
    /* background-color: #cd99f7; */
    border-radius: 15vh;
}
.Summer_country b{
    font-weight: bold;
    color: white;
}
.SummerCamp_CoursesCards{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.SummerCamp_CoursesCard{
    display: flex;
    flex-direction: column;
    border-radius: 2vh;
    margin: 1vh;
    width: 25vw;
}

.SummerCourseCard{
    border-radius: 2vh;
    display: flex;
    background-color: #0a6fda;
    padding: 1vh;
}
.Section1_CoursesCard{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    color: white;
    padding: 4%;
}
.Section1_CoursesCard b{
    font-size: 4vh;
    font-family: cursive;
}
.CoursesCards_Image{
    width: auto;
}

.Section2_CoursesCard {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    padding: 20px;
    width: auto; 
    margin: auto;
    text-align: center;
}
.Section2_CoursesCard div {
    padding: 10px;
}
.Section2_CoursesCard .header {
    font-weight: bold;
    font-size: 2.5vh;
}
.Section3_CoursesCard{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Section3_CoursesCard h3{
    font-size: larger;
    font-family: cursive;
    font-weight: bold;
    color: white;
}
.SummerCamp_CourseDetailbtn{
    padding: 3% 5% 3% 5%;
    color: white;
    font-weight: bold;
    border-radius: 1vh;
    margin: 2vh;
}

@media (max-width: 768px) {
    
    .SummerSection_1{
        width: -webkit-fill-available;
    }

    .SummerSection_btngrid{
        flex-direction: column;
        display: flex;
    }
    .SummerCamp_Image img{
        width: 90vw;
    }
    .Summer_countries{
        flex-direction: column;
    }
    .Summer_country{
        width: auto;
        height: auto;
        margin: 1vh;
    }
    .SummerCamp_CoursesCards{
        flex-direction: column;
    }
    .SummerCamp_CoursesCard{
        width: auto;
    }
    .CoursesCards_Image img{
        width: 30vw !important;
    }
}