.contactUs
{
    width: 70%;
    height: auto;
    margin: 2% 0 3% 25%;
}
.leftDiv
{
    width: 35%;
    height: 52vh;
    padding: 1%;
    background-color: #fff5f5;
    float: left;
}
.rightDiv
{
    width: 35%;
    height: auto;
    padding: 1%;
    background-color: #f7f5fa;
    float: left;
}
.contactHeading
{
    font-size: 20px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: #3b3b53;
    text-align: center;
    margin: 0 0 3% 0;
    font-weight: bold;
}
.Input
{
    width: 90%;
    height: 30px;
    border: 1px solid rgb(175, 175, 175);
    border-radius: 1vh;
    margin: 2%;
    padding: 3%;
}
/*.cnt
{
    width: 90%;
    height: auto;
    
    margin: 2%;
    padding: 3%;
}*/
.Input:hover{
    border: 2px solid rgb(56, 56, 196)

}
.btnSend
{
    padding: 3% 6% 3% 6%;
    background-color: #03b4c6;
    font-weight: bold;
    color: white;
    font-family: sans-serif;
    border: 0;
    border-radius: 1vh;
    margin-left: 25%;
}
.FB:hover{
    color: #475993;
    cursor: pointer;
}
.TW:hover{
    color: #03a9f4;
    cursor: pointer;
}
.YT:hover{
    color: #c61b1f;
    cursor: pointer;
}
.IN:hover{
    color: #ea4653;
    cursor: pointer;
}
.PIN:hover{
    color: #cb2027;
    cursor: pointer;
}
.FB,.TW,.PIN,.IN,.YT{
    color: gray;
}

@media only screen and (max-width: 1000px)
{
    .contactUs{    
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: 0;
        align-items: center;
        justify-content: center;
    }
    .leftDiv,.rightDiv
    {
        width: -webkit-fill-available;
    }
}
