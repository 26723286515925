
@media only screen and (max-width: 1000px)
{

    .feeTableContainer
    {
        display: flex;  
        width: 100%;
        margin: 0;
        overflow-x: scroll; /* Enable horizontal scrolling for mobile */
        scroll-snap-type: x mandatory;
        /* white-space: nowrap; */
    }
    .columns {
        
        flex: 1;
        min-width: 100%; /* Each section takes up the full width */
        padding: 20px;
        background-color: #f0f0f0;
        border: 1px solid #ccc;
        text-align: center;
        transition: transform 0.3s ease-in-out; /* Add slide transition */
        min-width: 100%; /* Each section takes up the full width */
        scroll-snap-align: start;

      }
}