.CommunicationPortalBackground
{
    width: 100%;
    height:600px;
    display: flex;
    background:  url('./CommunicationImages/portalBackground.png');
    background-size: cover; 
    position: relative;

}

@media only screen and (max-width: 1000px)
{
    .CommunicationPortalBackground
    {

        height: auto;
        width: auto;
    }
}
