.CourseRegistrationInputs
{
    width: 80%;
    height: auto;
    margin-left: 10%;
    
}
.RegisterInCourseFeeTextField

{
    padding: 1%;

}
.RegisterButtonDiv
{
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.RegisterButton 
{
    padding:  1% 1% 1% 1% ;
    border: 0;
    
    border-radius: 1vh;
    background-color: #03b4c6;
    color: white;
    font-size: 3vh;
}