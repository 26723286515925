*{
    padding: 0;
    margin: 0;
}

.MAOheading{
    margin: 0 5vw 0 5vw;
    display: flex;
    align-items: center;
}

.MAOactivity-image{
    width: 70px;
    margin-right: 20px;
}

.MAOactivity-image img{
    width: 100%;
}

.MAOactivity-text{
    color: rgb(216, 49, 98);
    font-size: 20px;
}

.MAOcontainer{
    margin: 0 5vw 0 5vw;
}

.MAOhead{
    display: flex;
}

.MAOquestions{
    flex: 5;
    background-color: rgb(216, 49, 98);
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: aliceblue;
    border-radius: 10px 0 0 10px;
    
}


#total-questions{
    margin-left: 2px;
}

.MAOprogress-bar{
    height: 5px;
    width: 130px;
    background-color: rgb(154, 168, 168);
    border-radius: 5px;
    margin-top: 5px;
}

#progress{
    height: 5px;
    width: 0;
    background-color: aqua;
    border-radius: 5px;
}

.MAOscore{
    flex: 1;
    padding-top: 3px;
    display: flex;
    flex-direction: column;
    background-color: rgb(64, 163, 64);
    color: white;
    text-align: center;
    border-radius: 0px 10px 10px 0;
    justify-content: space-between;
}

.MAOnumbered-score{
    background-color: rgb(254, 236, 242);
    border-radius: 15px;
    padding: 10px;

}

.MAObody{
    background-color: rgb(254, 236, 242);
    display: flex;
    border-radius: 15px 0 0 15px;
}

.MAOremaining-body{
    background-color: rgb(254, 236, 242);
    flex: 5;
    padding: 20px;
    display: grid;
    grid-template-columns: 4fr 2fr 1fr;
    column-gap: 10px;
    row-gap: 20px;

    border-radius: 15px 0 0 15px;

    
}

.MAOquestion{
    border-width: 2px;
    border-color: rgb(216, 49, 98);
    border-style: solid;
    text-align: center;
    color: rgb(216, 49, 98);
    font-size: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.MAOvolume{
    display: flex;
    align-items: center;
}

.MAOvolume img{
    width: 30px;
    margin-right: 5px;
}

.MAOplay-question{
    background-color: rgb(254, 236, 242);
    border: none;
    cursor: pointer;
}

.MAOplay-question img:active{
    background-color: rgb(254, 236, 242);
    border: none;
    cursor: pointer;
    width: 90px;
}

.MAOpicture img{
    width: 300px;
}


.MAOtime{
    display: flex;
    border: 2px solid red;
    height: 40px;
    border-radius: 20px;
    margin-bottom: 10px;
}

.MAOtime-text{
    background-color: red;
    color: white;
    border-radius: 18px;
    display: flex;
    align-items: center;
    padding: 15px;
}

.MAOtimer{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 15px;
}

.MAOmarks{
    display: flex;
    border: 2px solid rgb(170, 96, 0);;
    height: 40px;
    border-radius: 20px;
    margin-bottom: 10px;
}

.MAOmarks-text{
    background-color: rgb(170, 96, 0);
    color: white;
    border-radius: 18px;
    display: flex;
    align-items: center;
    padding: 15px;
}

.MAOmarker{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 15px;
}

.MAOinstructions-dropdown{
    background-color: rgb(254, 236, 242);
    color: rgb(216, 49, 98);
    padding: 15px;
    font-size: 15px;
}

.MAOanswer-submit{
    display: flex;
    max-height: 70px;
}

.MAOanswer-submit input::placeholder{
    font-size: 15px;
}

.MAOanswer{
    border: none;
    border-radius: 8px;
    padding: 15px 10px;
}

#submission{
    border: none;
    background-color: rgb(18, 18, 192);
    border-radius: 8px;
    color: white;
    padding: 15px 15px;
    cursor: pointer;
}

#marks-color{
    flex: 1;
    background-color: rgb(254, 236, 242);
    border: 1px solid rgb(216, 49, 98);
    border-radius: 0 10px 10px 0;
    overflow: scroll;
    max-height: 300px;
}

.MAObox{
    display: flex;
    border: 2px solid rgb(216, 49, 98);
    margin: 0 10px 2px 10px;
}

.MAObox1{
    flex: 1;
    border-width: 0 2px 0 0;
    border-color: rgb(216, 49, 98);
    border-style: solid;
    text-align: center;
}
.MAObox2{
    flex: 1;
    background-color: red;
    
}

.MAOremarks{
    margin: 0 5vw 0 5vw;
    display: flex;
    justify-content: space-between;
}

.MAOanswer-submit {
    display: flex;
    /* flex-direction: column; Stack items vertically */
     gap: 30px; /* Adds spacing between elements */
  } 
