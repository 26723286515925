.coursesDropDown
{
    width: 100%;
    height: auto;
     display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 2%;
    
}
.coursesOption
{
    width: 30%;
   
 
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    resize: vertical;

}
.progress
{
    background-color: #faf1e3;
    padding: 3%;
    /* width:50% ; */
    /* margin: 3% 0% 0%  25%; */
    
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    border-radius: 2vh;
    box-shadow: 0 3px 5px 0 rgba(100, 96, 96, 0.3); /* This creates a shadow effect */

}
.student-progress
{
    background-color: #faf1e3;
    padding: 3%;
    /* width:50% ; */
    /* margin: 3% 0% 0%  25%; */
    
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    border-radius: 2vh;
    box-shadow: 0 3px 5px 0 rgba(100, 96, 96, 0.3); /* This creates a shadow effect */
    background-color: rgb(250, 241, 227);
    border-radius: 10px;
    box-shadow: rgb(170, 170, 170) 2px 2px 4px 0px;
    opacity: 1;

}

.progressSection {
    display: flex;
    flex-direction: column;
    /* align-items: normal; */
    width: 100%;
  }
  .processSectionCol{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .progressGrid {
    /* display: grid; */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    /* grid-template-columns: auto auto; */
    column-gap: 20px;
    row-gap: 10px; 
  }
  .studentProgressSection {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* align-items: normal; */
  }
  
  .studentProgressGrid {
    display: flex;
    /* display: grid; */

    /* grid-template-columns: auto auto; */
    flex-direction: row;
    justify-content: space-around;
    column-gap: 20px;
    row-gap: 10px; 
  }
  
  .progressGrid label {
    font-size: 3vh;
    color: #da831e;
    height: 10vh;
    display: flex;
    align-items: center;
  }
  
  .MyProgressRemarks {
    display: flex;
    align-items: center;
    padding: 5px 0; 
  }
  
.progressHead
{
    width:75%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #da831e;
}
.progressNumbers
{
    width: 50%;
    display: flex;   
  
}
.MyProgressRemarks
{
    width: 10vh;
    height: 10vh;
    border-radius: 10vh;
    border: 2px solid white;
    text-align: center;
    background-color:#faf1e3;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2%;
    box-shadow: 0px 0px 3px 3px rgba(100, 96, 96, 0.3); /* This creates a shadow effect */

}
