.navLinks
{
  color: #03b4c6 !important;
  font-size: 16px;
  font-weight: bolder;
  font-family: sans-serif;
  cursor: pointer;
}
.navLinks:hover{
  color: white !important;
  background-color:#03b4c6;
  border-radius: 1vh;
}
.homeBackground
{

  background: url('./CourseImages/mainBackground.png');
  background-size:cover ;
}
.portalIntro{
  width: 100%;
  background: url('./CourseImages/portalBackground.png');
  background-size:cover ;
  margin: 4% 0 4% 0;
}
.IntroDesc,.IntroImages{
  width: 50%;
  height: auto;
  float: left;
}
.IntroImage1{
  width: 50%;
  height: 50%;
  
}
.IntroImage2{
  width: 30%;
  height: 30%;
}
.IntroImages{
float: right;
display: flex;
align-items: center;
justify-content: flex-end;

}
.portal_heading
{
  width: 100%;
  padding: 0 3vh 0 3vh;
}
.portal_heading p
{
    font-family: 'VanillaMilk';
    font-size: 40px;
    color: #8b0194;
    font-weight: bold;
    text-align: center;
    padding: 20px;
}
.card {
    /* Add shadows to create the "card" effect */
    max-width: 340px;
    height: 30vh;
    margin: auto;
    transition: 0.3s;
    border-radius: 0px 0px 13px 13px;
  }

.card-color1{
    background-color: #096fdb;
    
}
.card-color2{
    background-color: rgb(245, 69, 56);
    
}.card-color3{
    background-color: rgb(157,65,148);
    height: 35vh;
    
}
.card-color5{    background-color: rgb(255,167,34);  }
.card-color4{    background-color: rgb(50,176,214);}
.card-color6{    background-color: #83a6c6;}
.card-color7{    background-color: #ff5e00;}
.card-color8{    background-color: #224c8d;}
.card-color9{    background-color: #a68969;}

  .img{
    width: 340px;
    height: 320px; 
    margin: auto;
    background-size: cover;
    background-position: center;
    position: relative;
    background-repeat:no-repeat ;
  }
.img1 {
    background-image: url('./CourseImages/MathPortal.png'); 
}.img2 {
    background-image: url('./CourseImages/CodingPortal.png'); 
}.img3 {
    background-image: url('./CourseImages/GraphicDesigningPortal.png'); 
}.img4 {
    background-image: url('./CourseImages/QuranPortal.png'); 
}.img5 {
    background-image: url('./CourseImages/BusinessPortal.png'); 
}.img6 {
  background-image: url('./CourseImages/EnggineeringPortal.png'); 
}
.img7 {
  background-image: url('./CourseImages/Architecture.png'); 
}
.img8 {
  background-image: url('./CourseImages/spacePortal.png'); 
}
.img9 {
  background-image: url('./CourseImages/CommunicationPortal.png'); 
}
.portalsTickIcons{
  display: flex;
    align-items: center;
    justify-content: center;
}
.tickIcon{
  width: 45%;
}
  /* On mouse-over, add a deeper shadow
   */
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  /* Add some padding inside the card container */
  .container {
    padding: 0px 16px;
  }
  .break {
    flex-basis: 100%;
    height: 0;
  }
  .H4_Heading{
    font-family: 'Barlow', sans-serif;
    font-size: 32px;
     font-weight: 400; 
      color: rgb(255, 255, 255);
      margin: 10px 0px 20px 0px;
  }
  .fa-check{
    color: white;
  }
  .course-details{
    font-family: sans-serif;
    margin-left: 5px;
    font-weight: lighter;
    font-size: 12px;
  }
.portalHighlights{
    font-family: 'VanillaMilk';
    font-size: 60px;
    color: #8b0194;
    text-align: center;
    z-index: 1;
    padding: 20px;
    /* width: fit-content; */
}

.portalMainPara,.portalBullets,.portalPara{
    width: 100%;
    font-family:sans-serif;
    font-size:115%;
    color: #8b0194;
    /* text-align:center;  */
    padding: 2vh;
    margin-left: 10%;
}
.CEOMsg p{
    width: 80%;
    font-family:sans-serif;
    font-size:120%;
    /* text-align:center;  */
    padding: 2vh;
    margin-left: 10%;
}
.portalPara{
  width: 80%; 
}

.portalBullets{
  width: 80%;
  /* text-align:left;  */
  margin-left: 15%;
}
.portalBtn
{
 width:auto;
 padding:1%;
 background-color: #03b4c6;
 color: white;
 border: 0;
 border-radius: 1vh;
 font-weight: bold;
 margin: 1vh;
}
.teacherTestimonal{
    width: 100%;
    height: 80vh;
    background:  url('./CourseImages/teacherTestimonal.png');
    background-size: cover;
    background-position: center;
    display: flex;
  align-items: center;
  justify-content: space-evenly;
  float: left;
  margin-top: 2%;

}
.testimonalBox1,.testimonalBox2{
  width: 60vh;
  height: 60vh;
  background-color: white;
  border: 0;
  border-radius: 5vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.teacherName{
  color: #ffb52f;
  text-align: center;
  margin: 10%;
}
.kidSchool{
  background:  url('./CourseImages/kidSchool.png');
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  float: left;
  margin-top: 2%; 
}
.CEOMsg
{
  background:  url('./CourseImages/CeoMsgBg.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5%;
}

.footer{
  width: 100%;
  height:auto;
  min-height: 550px;
  background: url('./CourseImages/footer.png');
  background-color: rgb(237, 255, 255);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  /* background-color: #04b2ad; */
  display: flex;
  align-items: center; 
  padding: 0 0 6% 0;
}
.footerImg{
  float: left;
}
.footerContent{
  margin-left: 5%;
  width: 90%;
  margin-top: 0%;
}
.footerText{
  margin-top: 45%;
  width: 30%; 
  float: left;
  font-size: 15px;
  font-family: sans-serif;
  color:#ffffff
}
.footerLinksSection
{
  display: flex;
  align-items: start;
  justify-content: center;
}
.footerLinks,.footerLinks1,.footerLinks2
{
  margin-top: 46vh;
margin-left: 20%;
width: 25%;
  float: left;
  font-size: smaller;
  color: white;
  font-family: sans-serif;
}
.footerLinks2
{
width: 30%;
}
.footerLinks1{
  margin-left: 5%;
  
  }
.footerLinks2{
    margin-left: 5%;
    
}

.avatar-image {
  border-radius: 50%; /* Makes the image circular */
  width: 40px; /* Set the width */
  height: 40px; /* Set the height */
  margin: 0;
  padding: 0;
}

.navLinksArrowHide::after{
  display: none !important; /* Hide the dropdown arrow icon */
} 