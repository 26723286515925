.student-ActivityStartPage{
    font-family: sans-serif;
    font-size: 4vh;
    padding: 3%;
    color: white;
    text-align: center;
}

.StartActivityContainer{
    width: auto;
    height: auto;
    padding: 5% 7% 5% 7%;
    background-color: rgb(255, 255, 255);
    box-shadow: 10px 10px 10px 10px rgb(185, 184, 184);
    border-radius: 2vh;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    text-align: center;
    width: 50%;
    margin-left: 30%;
}


.ActivityStartDetails{
    display: flex;
    align-items: center;
    justify-content: flex-start;

}