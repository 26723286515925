.ScratchProg-Background{
    width: 100%;
    height:600px;
    display: flex;
    background:  url('./CodingCourseImages/portalBackground.png');
    background-size: cover; 
    position: relative;
}
.scratch-background{
  
    display: flex;
    background:  url('./CodingCourseImages/scratchbackground.png');
    background-size: cover; 
    position: relative;
    align-items: center;
    justify-content: center;
}
.round-box{
    border: 4px solid #aee5b6;
    background-color: #249935;
}
.round-box1{
    border: 4px solid #d9defb;
    background-color: #485cd9;
}

.small-box{
    background-color: #cff7d5; 
}
.small-box1{
    background-color: #d9defb; 
}
.large-box{
    background-color: #cff7d5;
}
.large-box1{
    background-color: #d9defb;
}
 

@media only screen and (max-width: 1000px)
{
    .ScratchProg-Background{
        height: auto;
    }
    .large-box
    {
        height: auto;
    }
}