/* *{
    margin: 0;
    padding: 0;
} */

.CourseRegistrationTable{
    margin: 70px 10px ;
    background-color: aqua;
    /* border: 1px solid gray; */
    border-collapse: collapse;
}

.CourseRegistrationTable-th,.CourseRegistrationTable-tr,.CourseRegistrationTable-td{
    /* border: 1px solid gray; */
    max-width: 20%;
    text-align: center;
    /* padding:0 2% 0 2%/; */
}

.CourseRegistrationTable-th{
    color: rgb(255, 255, 255);
    padding: 10px;
    background-color: rgb(135, 138, 150);
}

.CourseRegistrationRadioType{
    text-align: start;
    padding-left: 5px;
    font-size: 14px;    
}

.CourseRegistrationSelect,.CourseRegistrationOption{
    max-width: 100%;
    min-width: 30%;
    min-height:2vh;
    
}

.CourseRegistrationTable-td{
    background-color: rgb(200, 223, 250);
}

.CourseRegistrationButton{
    background: none rgb(0, 158, 176);
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 15px;
    font-weight: bold;
    padding: 8px;
    cursor: pointer;
}

.type-section{
    display: flex;
    align-items: center;
}

.CourseRegistrationInput{
    cursor: pointer;
}

.CourseRegistrationLabel{
    padding-left: 3px;
}


.CourseRegistrationButton.disabled {
    background-color: rgba(0, 0, 0, 0.1); /* Add a background blur effect */
}

.CourseRegistrationButton.disabled:hover {
    cursor: not-allowed; /* Change cursor to not-allowed when button is disabled */
}

.CourseRegistrationButton.disabled:hover svg {
    color: red; /* Change color of the cross icon when button is hovered */
}
