 .edit-bar{
    min-width: auto;
    order: 2;
    min-height: auto; 
    height: max-content;
    flex-grow: 0;
    flex-shrink: 0;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
 
    background-color: rgb(0, 100, 128);


}
.student-EditHeading{
    margin-left: 40%;
    width: auto;
    height: auto;
    flex-grow: 1;
    font-family: "Open Sans";
    font-size: 50px;
    font-weight: 300;
    color: rgb(255, 255, 255);
    line-height: 1.3;
    border-radius: 0px;
} 
/* Style inputs, select elements and textareas */
 .editInput{
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    resize: vertical;
  } 
  
  /* Style the label to display next to the inputs */
    .StudentEditProfileLabel {
    padding: 12px 12px 12px 0;
    display: inline-block;
  }
   
  /* Style the submit button */
   .SubmitBtn {
    background-color: #04AA6D;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: right;
    width: 30%;
    /* margin-left: 30%; */
  }  
  
  /* Style the container */
   .StudentEditProfileContainer {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
    width: 40%;
  }
    
  /* Floating column for labels: 25% width */
   .col-25 {
    float: left;
    width: 25%;
    margin-top: 6px;
    /* font-weight: bold; */
    font-size: 15px;
  }
    
  /* Floating column for inputs: 75% width */
    .col-75 {
    float: left;
    width: 75%;
    margin-top: 6px;
  }
  
  /* Clear floats after the columns */
    .row:after {
    content: "";
    display: table;
    clear: both;
  }  
  /* .row{
    display: flex;
    align-items: center;
    justify-content: center;
    width: -webkit-fill-available;
  } */
  
   /* // Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .col-25, .col-75  {
      width: 100%;
      margin-top: 0;
    }
  } 
  @media screen and (max-width: 1000px) 
  {
    .StudentEditProfileContainer {
      width: 90%;
    }
    .student-EditHeading
    {
      text-align: center;
      margin-left: 0;
    }
  }
  /* Add this to your existing CSS or create a new CSS file */
/* .gender-container {
  display: flex;
  align-items: center;
} */
