.CivilPortalBackground{
    width: 100%;
    height:600px;
    display: flex;
    background:  url('./EngineeringCourseImages/portalBackground.png');
    background-size: cover; 
    position: relative;

}

.RB1{
    
    background-color: #999924;
    border: 2px solid  #f8f8da;
}
.SB1{
    
    background-color: #f8f8da;
    border: 2px solid #999924;

}
.LB1{
    background-color: #f8f8da;
    border: 2px solid #999924;
    

}
@media only screen and (max-width: 1000px)
{
    .CivilPortalBackground{

        height: auto;
        width: auto;
    }
    .ActivityLabels{
        width: 53%;
    }
    .civilActivitties
    {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}