.custom-radio input[type="radio"] {
  display: none;
}

.custom-radio input[type="radio"] + label {
  position: relative;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s, color 0.3s;
}

.custom-radio input[type="radio"]:checked + label {
  background-color: #97e05b;
  color: #fff;
}
.materialLink:hover{
  color: #000;
}

.form-column {
  background-color: greenyellow;
  display: flex;
  flex-wrap: wrap;
}

.manage-course {
  flex: 1;
  margin: 5px;
}
