/* Specific CSS classes for elements */
.edit-lesson {
  background-color: lightblue;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.edit-lesson h2 {
  color: white;
}

.edit-lesson .form-group {
  margin-bottom: 20px;
}

.edit-lesson label {
  display: block;
  margin-bottom: 5px;
  color: white;
}

/* Apply responsive styles */
@media screen and (max-width: 768px) {
  .edit-lesson {
    padding: 10px;
  }

  .edit-lesson .form-group {
    margin-bottom: 10px;
  }
}

/* White background for input fields and textareas */
.edit-lesson input[type='text'],
.edit-lesson textarea {
  width: 100%;
  padding: 10px;
  background-color: white; /* Add this line to make input fields white */
}
