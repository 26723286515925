/* Base styles for all devices */
.manage-course-container {
  display: flex;
  flex-wrap: wrap;
}

.course-list,
.module-list,
.lesson-list {
  flex: 1 1 100%;
  padding: 10px;
  margin: 10px;
  background-color: #dceb7b;
  border-radius: 4px;
  max-height: 1000px;
  overflow-y: auto;
  max-width: 200px;
}

.additional-components{
  flex: 1 1 100%;
  padding: 10px;
  margin: 10px;
  background-color: #dceb7b;
  border-radius: 4px;
  max-height: 1000px;
  overflow-y: auto;
}

.course-item,
.module-item,
.lesson-item,
.component-item {
  cursor: pointer;
  padding: 5px;
  margin-bottom: 5px;
  background-color: #f9fbfd;
  border-radius: 4px;
}

.selected {
  background-color: #5e9fe4;
}

/* Responsive styles for larger screens (min-width: 768px) */
@media (min-width: 768px) {
  .manage-course-container {
    flex-wrap: nowrap;
  }

  .course-list,
  .module-list,
  .lesson-list,
  .additional-components {
    flex: 1 1 auto;
  }
}
