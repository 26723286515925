.Salary-Section{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.SalaryCal-Section{
    width: 50%;
    height: auto;
    border-radius: 2vh;
    background-color: #c9e0c9;
    padding: 1%;

}
.SalaryFilter{
    width: auto;
    height: auto;
    padding: 1%;
    border-radius:2vh ;
    background-color: #99dd98;
}
.Salary-MetaData{
    display: flex;
    align-items: center;
    justify-content: center;
}
.Salary-MetaData  select{
    width: 10vw;
    border: 0;
}

.Salary-Demo-Price{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.DemoPrice,.DemoTotal{
    width: auto;
    padding: 4%;
    margin:2% 0 2% 0 ;
    background-color: #99dd98;
    border-radius: 2vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    float: left;
}
.DemoInputs{
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom:3vh ;

}
.Demo-input{
    width: 10vw;
    color: gray;
    border: 0;
    padding: 2%;
    border-radius: 1vh;
}

.GenSalary{
    display: flex;
    align-items: center;
    justify-content: center;
}
.SalaryBtn{
    background-color: #99dd98;
    border-radius: 2vh;
    padding: 2%;
    font-weight: bold;
    border: 0;
    color: rgb(26, 24, 24);
}


/* salary */
/* CSS File (e.g., styles.css) */

.SAL-Teacher{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.salary-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .salary-table th,
  .salary-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #dddddd;
  }
  
  .salary-table th {
    background-color: #f2f2f2;
  }
  
  .salary-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .salary-table tbody tr:hover {
    background-color: #e9e9e9;
  }
  