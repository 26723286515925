.KidPreneur-Background{
    width: 100%;
    height:600px;
    display: flex;
    background:  url('./BusinessCourseImages/portalBackground.png');
    background-size: cover; 
    position: relative;
}
.ActivityImages{
    width: 15vw;
    height: 20vh;
}
@media only screen and (max-width: 1000px)
{
    .KidPreneur-Background{
        height: auto;
        width: auto;
    }
    .activityImagesContainer,.row{
        align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    }
    .ActivityImages
    {
        width: auto;
        height: auto;
    }
}