p{
    margin-bottom: 0rem;
}
.MAcard{
    background: cyan;
    width: 30vw;
    height: auto;
    margin-top: 20px;
}
.MAcard ul li form{
    display: inline;
    margin-left: 13px;
}
.MAcard ul li{
    display: inline;
    list-style: none;
    justify-content:space-around;
    justify-items:auto;
}
.MAprofile-picture img{
    width: 50px;
    border-radius: 50%;
    display: inline-block;
}
.MAdeactive-status input{
    display: inline-block;
}
#deactivate-text{
    display: inline-block;
}
.MAprofile-picture{
    
}

/* body{
    margin-top: 20px;

    margin-bottom: 1000px;
} */

.MAcards-in-row{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 30px;
    row-gap: 30px;
    padding: 10px 20px;

}

.MAcard{
    background-color: rgb(248, 248, 248);
   
    border: 2px solid black;
    border-radius: 10px;
}

.MAcard-head{
    display: flex;
    justify-content: space-between;

}

.MAdeactive-status{
    flex: 1;
    display: flex;
    align-items: center;
    padding: 10px 10px 20px 10px;
}

.MAdeactive-checkbox{
    margin-right: 5px;
    margin-bottom: 0;
}

.MAprofile-picture{
    flex: 2;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.MAuser-profile-picture{
    height: 50px;
    border-radius: 25px;
}

.MAuser-role{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.MAdropdown-menu{
    font-size: 16px;
}


.MAuser-details{
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
}

@media screen and (max-width: 700px) 
{
    .MAuser-details{
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        padding: 5px 10px;
    }
}

.MAdob-phone p{
    text-align: end;
}

.MAcourse-module{
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    padding: 15px 10px;
    align-items: center;
}
.MAsave-button{
    display: flex;
    justify-content: space-around;
    padding: 0 10px 10px 0;
    align-items: center;
}
.MAsave-button a:hover{

    color: black;
 
   
}
.MAsave-button button{
    background-color: rgb(3, 180, 198);
    border: none;
    color: white;
    padding: 8px 50px;
    border-radius: 4px;
   

}

.MAtype-message{
    display: flex;
}

.MAtype-message input{
    flex: 1;
    padding: 9px 6px;
    margin: 0 10px;
}

.MAcard-foot{
   display: grid;
   grid-template-columns: 1fr 1fr;
   padding: 10px 10px;

}

.MAcard-foot div a{
    text-decoration: none;
    color: rgb(3, 180, 198);
    font-weight: bold;
}

.MAupper-right{
    display: flex;
    justify-content: center;
}

.loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; 
  }
  
  .loading-container p {
    margin-top: 10px; 
    font-size: 16px; 
    color: rgb(3, 180, 198); 
  }
  
  @media screen and (max-width: 1090px) 
  {
      .MAcards-in-row{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
      }
      .MAcard{
          width: 60%;
      }
  }
@media screen and (max-width: 700px) 
{
    .MAcards-in-row{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .MAcard{
        width: 90%;
    }
}



.MAdropdown-fixed-width {
    width: 100px; /* Set the desired width here */
  }
  /* Existing styles... */

.search-filters {
    display: flex;
    justify-content: space-between; /* Distributes space between elements */
    align-items: center; /* Centers items vertically */
    margin: 20px 10%; /* Adds margin above and below and 10% margin on the sides */
}

.search-filters input {
    flex: 1; /* Allows the input to grow */
    padding: 8px; /* Decreased padding for a smaller input */
    border: 1px solid #ccc; /* Sets a border color */
    border-radius: 4px; /* Rounds the corners */
    margin-right: 10px; /* Adds space between the input and dropdowns */
    max-width: 250px; /* Sets a maximum width for the input */
}

.MAdropdown-menu {
    padding: 8px; /* Decreased padding for a smaller dropdown */
    border: 1px solid #ccc; /* Sets a border color */
    border-radius: 4px; /* Rounds the corners */
    flex: 1; /* Allows dropdowns to grow */
    /* margin-left: 10px;  */
    /* max-width: 200px; */
    /* width:80%; */
}
.search-filters-container {
    background-color: #003366; /* Dark blue background */
    padding: 5px; /* Adds padding inside the background box */
    border-radius: 1px; /* Rounds the corners of the box */
    margin: auto /* Keeps margin for positioning */
}


@media screen and (max-width: 700px) {
    .search-filters {
        flex-direction: column; /* Stacks elements vertically on small screens */
        align-items: stretch; /* Stretches items to full width */
    }

    .search-filters input,
    .MAdropdown-menu {
        margin: 5px 0; /* Adds vertical spacing between elements */
        max-width: 100%; /* Makes inputs and dropdowns full width on small screens */
    }
}
