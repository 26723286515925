
  /* Style the container */
  /* .container {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
    width: 30%;
  } */
  /* Style inputs, select elements and textareas */
 .PasswordFields{
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    resize: vertical;
  }  
   
  .forgotPassword:hover{
    color: black;
    
  }
  .forgotPassword{
    align-items: center;
    display: flex;
    justify-content: center;
  }
  
   
   
  /* Style the submit button */
   .ChangePasswordSubmitBtn{
    background-color: rgb(0, 100, 128);
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: right;
    width: auto;
    /* margin-left: 24%; */
    margin-top: 3%;
  }  
  
  /* Style the container */
   .ChangePasswordContainer{
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
    width: 40%;
    margin-left: 33%;
  }
    
  /* Floating column for labels: 25% width */
   .col-25 {
    float: left;
    width: 25%;
    margin-top: 6px;
    /* font-weight: bold; */
    font-size: 15px;
  }
    
  /* Floating column for inputs: 75% width */
    .col75 {
    float: left;
    width: auto;
    margin-top: 6px;
  }
  .row{
    justify-content: center;
  }
  /* Clear floats after the columns */
    .row:after {
    content: "";
    display: table;
    clear: both;
  }  
  .ChangePasswordForm
  {
    /* margin-left: 20%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
   /* // Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .col-25, .col-75, .ChangePasswordSubmitBtn {
      width: 100%;
      margin-top: 0;
    }
  } 
  @media screen and (max-width: 1000px) {
  
    .ChangePasswordContainer{
      width: auto;
      margin: 0;
    }
    .ChangePasswordSubmitBtn{
      margin-left: 0;
      width: auto; 
    }
    .forgotPassword
    {
      align-items: center;
      display: flex;
      justify-content: center;
    }
    .ChangePasswordForm
    {
      align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0;
    }
    
  }