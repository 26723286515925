.EnggPortalBackground
{
    width: 100%;
    height:600px;
    display: flex;
    background:  url('./EngineeringCourseImages/portalBackground.png');
    background-size: cover; 
    position: relative;

}

@media only screen and (max-width: 1000px)
{
    .EnggPortalBackground{

        height: 1000px;
        width: auto;
    }
}
