.leaderboard-container {
    font-family: Arial, sans-serif;
    margin: 20px auto;
    max-width: 900px;
    text-align: center;
    color: #333;
  }
  
  .leaderboard-header h1 {
    color: #004d66;
    margin-bottom: 20px;
  }
  
  .leaderboard-message p {
    color: #4caf50;
    font-size: 1.2em;
    margin: 10px 0;
  }
  
  .leaderboard-summary {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
    background-color: #e6f7ff;
    padding: 10px;
    border-radius: 5px;
  }
  
  .summary-item {
    flex: 1;
    text-align: center;
    font-size: 1em;
  }
  
  .leaderboard-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .leaderboard-table th,
  .leaderboard-table td {
    border: 1px solid #ddd;
    padding: 10px;
  }
  
  .leaderboard-table th {
    background-color: #4caf50;
    color: white;
  }
  
  .leaderboard-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .leaderboard-table tr:hover {
    background-color: #f1f1f1;
  }
  