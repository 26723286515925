
@media only screen and (max-width: 1000px)
{
    .colsm{
        /* width: auto;
        height: auto;
        display: block; */
    /* min-width: 0; */
flex-basis: 0;
}
    .btn-row-resp{
        /* display: block; */
        /* flex-direction: column;
        width: 200px;
        height: auto;
        align-items: center;
        justify-content: center; */
        /* margin-left: 10%;
        margin-top: 5em; */
    }
    .benefit-detail-background-resp{
        height: 100%;
    }
    .benefit-resp2{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 10vh;
        width:90%;
        float: right;
    } 
    .resp-text{
        left: 10vh;
    }
    /* .imgbene-resp{
        width: 90%; 
    display: inline-flex;
    } */
    .benefit-resp{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width:90%;
        float: right;
    }
    .arrow-resp{
        margin-left: 0vh;
    }
    .respon{
        /* flex-direction: column;
        align-items: center;
        justify-content: center; */

    }
    .section
    {
        flex-direction: column;
    }
    .course-structure{
        flex-direction: column;
    }
     .MathPortalBackground{
        height: 1600px;
     }
    .modules{
        display: flex;
        flex-direction: column;
        
    }
    .smallbox{
        /* display: flex;
        flex-direction: column; */
        margin-top: 5vh;
        /* width: auto;
        height: auto; */
        

    }
    .largebox{
        
        display: flex;
        flex-direction: column;
        margin-top: 15vh;
        width: auto;
        height: auto;
    }
    .section-left
    {
        flex-direction: column;
    }
    .Text-small
    {
        text-align: center;
        position: relative;
    }
    
    .Text-big
    {
        text-align: center;
    }
      
  /* Change the width of the three columns to 100%
  (to stack horizontally on small screens) */

    .columns {
      width: 100%;
    }
    
}

