.unauthorized-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8d7da;
  }
  
  .unauthorized-box {
    border: 1px solid #f5c2c7;
    background-color: #f8d7da;
    color: #721c24;
    padding: 20px;
    text-align: center;
    border-radius: 5px;
  }
  
  .unauthorized-title {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .unauthorized-message {
    font-size: 1.2em;
  }
  