.ArchitecturePortalBackground{
    width: 100%;
    height:600px;
    display: flex;
    background:  url('./ArchitectureCourseImages/portalBackground.png');
    background-size: cover; 
    position: relative;

}

.RB1{
    
    background-color: #999924;
    border: 2px solid  #f8f8da;
}
.SB1{
    
    background-color: #f8f8da;
    border: 2px solid #999924;

}
.LB1{
    background-color: #f8f8da;
    border: 2px solid #999924;
}

.RB2{
    
    background-color: #b8584a;
    border: 2px solid  #f4dedb;
}
.SB2{
    background-color: #f4dedb;
    border: 2px solid  #b8584a;


}
.LB2{
    background-color: #f4dedb;
    
    border: 2px solid  #b8584a;
}

@media only screen and (max-width: 1000px)
{
    .ArchitecturePortalBackground{

        height: auto;
        width: auto;
    }
}