/* Specific CSS classes for elements */
.edit-module {
  background-color: #e0a44a;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.edit-module h2 {
  margin-bottom: 20px;
}

.form-field {
  margin-bottom: 10px;
}

.form-field label {
  display: block;
}

.form-field input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.inz-dropdown{
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button-row {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}


.delete-icon {
  color: #f44336;
  cursor: pointer;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .edit-module {
    padding: 10px;
  }

  .form-field input {
    padding: 6px; /* Reduce padding for smaller screens */
  }
}
