.inz-button {
    text-align: center;
}

.inz-save-button {
    width: auto;
    padding: 3% 6% 3% 6%;
    background-color: white;
    font-weight: bold;
    color: #03b4c6;
    font-family: sans-serif;
    border: 2px solid #03b4c6;
    border-radius: 1vh;
    margin: 3% auto 0;
    display: block;
    word-wrap: break-word;
}

.inz-save-button:hover {
    background-color: #03b4c6;
    color: white;
}

.inz-button-row{
    display: flex;
    text-align: justify;
}

.inz-delete-button {
    width: auto;
    padding: 3% 6% 3% 6%;
    background-color: rgb(255, 56, 56);
    font-weight: bold;
    color: white;
    font-family: sans-serif;
    border: 2px solid black;
    border-radius: 1vh;
    margin: 3% auto 0;
    display: block;
    margin-left: 10px;
}

.inz-delete-button:hover {
    background-color: red;
    color: white;
}

.inz-deletesp-button {
    width: auto;
    padding: 3% 6% 3% 6%;
    background-color: rgb(255, 56, 56);
    font-weight: bold;
    color: white;
    font-family: sans-serif;
    border: 2px solid black;
    border-radius: 1vh;
    margin: 3% auto 0;
    display: block;
    margin-left: 10px;
    height: auto;
}

.inz-deletesp-button:hover {
    background-color: red;
    color: white;
}

.inz-update-button {
    width: auto;
    padding: 3% 6% 3% 6%;
    background-color: rgb(56, 255, 142);
    font-weight: bold;
    color: white;
    font-family: sans-serif;
    border: 2px solid black;
    border-radius: 1vh;
    margin: 3% auto 0;
    display: block;
    height: auto;
}

.inz-update-button:hover {
    background-color: rgb(6, 236, 106);
    color: white;
}
