.PolymatchSection_2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; /* Center the buttons */
    gap: 20px; /* Consistent spacing */
    margin-top: 20px; /* Add space above the section if needed */
}

.Polymatch_Age4, .Polymatch_Age5, .Polymatch_Age8, .Polymatch_Age11 {
    width: auto; /* Set consistent width */
    padding: 15px 25px; /* Uniform padding */
    border-radius: 8px; /* Rounded corners */
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth hover effect */
}
.Polymatch_Age4 {
  background-color: #dd2ba2;
}
.Polymatch_Age5 {
    background-color: #dbaf1f;
}

.Polymatch_Age8  {
    background-color: #688cd9;
}
.Polymatch_Age11{
    background-color: #58da6e;
}
.Polymatch_Age4:hover, .Polymatch_Age8:hover, .Polymatch_Age11:hover {
  background-color: #483d8b; /* Darker hover color */
  color: white; /* Change text color on hover for contrast */
}
.Polymatch_Age4 a, .Polymatch_Age8 a, .Polymatch_Age11 a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
}


.Polymatch_Age5:hover, .Polymatch_Age8:hover, .Polymatch_Age11:hover {
    background-color: #483d8b; /* Darker hover color */
    color: white; /* Change text color on hover for contrast */
}

.Polymatch_Age5 a, .Polymatch_Age8 a, .Polymatch_Age11 a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
}

.PolymatchResultsText {
    z-index: 22;
    align-self: center;
    height: max-content;
    margin: 0px;
    white-space: pre-wrap;
    overflow: visible;
    word-break: break-word;
    font-family: 'VanillaMilk'; /* Ensure you have imported or have access to the Vanilla font */
    font-size: 30px;
    color: rgb(139, 1, 148);
    text-align: center;
    line-height: 1.4;
    border-radius: 0px;
    opacity: 1;
}


@media (max-width: 768px) {
    .PolymatchSection_2 {
        flex-direction: column; /* Stack vertically on smaller screens */
        gap: 15px; /* Adjust spacing for smaller screens */
    }
}
.results-table-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    overflow-x: auto;
  }
  
  .results-table {
    border-collapse: collapse;
    width: 90%;
    max-width: 1000px;
    font-family: 'Vanilla', sans-serif; /* Assuming the Vanilla font is correctly installed */
    font-size: 16px;
    color: #000;
    text-align: left;
  }
  
  .results-table th, .results-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .results-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #f2f2f2;
    color: #333;
  }
  
  .results-table td {
    text-align: center;
  }
  
  .results-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .results-table tr:hover {
    background-color: #f1f1f1;
  }
  

