*{
    padding: 0;
    margin: 0;
}

.MAVideoHeading{
    margin: 0 30vw 0 15vw;
    display: flex;
    align-items: center;
}

.MAVideoActivity-image{
    width: 70px;
    margin-right: 20px;
}

.MAVideoActivity-image img{
    width: 100%;
}

.MAVideoActivity-text{
    color: rgb(52, 151, 156);
    font-size: 20px;
}

.MAVideoContainer{
    display: flex;
    justify-content: center;
}

.MAVideoContainer iframe{
    margin: 0 5vw 0 5vw;
    height: 70vh;
    width: 70vw;
}
