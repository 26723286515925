.ResetButton {
    background-color: #03b4c6;
    color: white;
    font-weight: bold;
    border-radius: 3vh;
    width: 50%;
    padding: 2vh;
    font-size: large;
    cursor: pointer;
    border: none;
  }
  
  .ResetButton:hover {
    background-color: white;
    color: #03b4c6;
    border: 2px solid #03b4c6; /* Add red border on hover */
  }
  