.popup-inz {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .popup-inner-inz {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    width: 500px;
  }

  .popup-header-inz {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .close-icon {
    cursor: pointer;
  }

  .popup-content-inz {
    margin-bottom: 20px;
  }

  .popup-actions-inz {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .redeem-btn,
  .see-rewards-btn {
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    border-radius: 5px;
    background-color: rgb(3, 180, 198);;
    color: white;
    cursor: pointer;
  }

  .redeem-btn:hover,
  .see-rewards-btn:hover {
    background-color: rgb(3, 144, 159);;
  }
