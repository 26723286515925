.about{
    width: 100%;  
    height: auto;
background-color: e0f7fa;
margin: 0;
padding: 0;
overflow: hidden; /* Hides any overflow content */

}

.innerAbout
{
    width: 55%;
    padding-top:5% ;
    margin-left: 20%;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.mainHeading
{
    font-family: 'VanillaMilk';
    font-size: 35px;
    color: #8b0194;
    text-align: center;
    z-index: 1;
    padding: 20px;
}

.para
{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size:18px; 
    text-align: left;
    color: #000000; 

    z-index: 1; 
}
.owner
{
    width: 100%;
    height: auto;
    background-color: #f0eded;
}

@media only screen and (max-width: 1000px)
{
    .innerAbout{
        width: -webkit-fill-available;
        margin-left: 0;
    }
}
