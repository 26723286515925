.main{
    height: 70vh;
    width: 30%;
    position: absolute;
    margin: 1% 0% 0 0%;
    left: 40%;   
}

.link:hover{
color: black;
}
.inp
{
    width: 90%;
    height: auto;
    border: 1px solid rgb(175, 175, 175);
    border-radius: 1vh;
    margin: 2%;
    padding: 3%;
}
.imp
{
  width: 95%; /* Match other input fields */
  height: 2.9em; /* Same height as other fields */
  border: none; /* Remove the outer border */
  border-bottom: 0px solid rgb(175, 175, 175); /* Add a bottom border if needed */
  border-radius: 0; /* Match other input fields' border style */
  padding: 0.5em; /* Match padding with other fields */
  font-size: 1em; /* Ensure font consistency */
  outline: none; /* Remove focus outline */
  box-sizing: border-box; /* Ensure padding doesn't affect dimensions */
}
.inp:hover{
    border: 2px solid #03b4c6;

}
.btn
{
  width:20vw;
    padding: 3% 6% 3% 6%;
    background-color: white;
    font-weight: bold;
    color: #03b4c6;
    font-family: sans-serif;
    border: 2px solid #03b4c6;
    border-radius: 1vh;
    margin: 3% 0 0% 10%;
    text-align: center;
    word-wrap: break-word;
    
}
.btn:hover{
    background-color: #03b4c6;
    color:white ;
}
 
.UserDropdown
{
    font-size: 20px;
    color: #03b4c6;
}
.UserDropdown:hover
{
    color: white !important;
    background-color:#03b4c6;
}
 
   .dropdown-container {
    position: relative;
    display: inline-block; 
  }
    
  .dropdown-menu_ {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 999;
    background-color: #edffff;
    border: 1px solid #03b4c6; 
  }
  
  .course-text {
    display: block;
    white-space: normal;
    overflow-wrap: break-word;
  }
  
  
  
  .overlay1133,.overlay1122 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 9998;
  }
  .popup{
    height: 70vh;
    width: 100%;
    background-color: rgb(255, 255, 255);
    position: absolute; 
    overflow-x:inherit;
    overflow-y:auto;
    padding-left: 5%;
}
.popup-header{
    display: flex;
    justify-content: space-between;
    padding: 0 30px 0 15px; 
}

.SignUP-avatar-upload {
  display: flex;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #e0e0e0;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.SignUP-avatar-upload img {
  max-width: 100%;
  max-height: 100%;
}
.Signupbtn
{
  border: 0;
  border-radius: 0;
  
}

.Navbar-Right-Aligned
{
  justify-content: flex-end; 
}
.user-profile-dropdown
{
  border:1px  solid #03b4c6;
  background-color:#edffff;
  margin-left: -80%;
}
.pageLogo
{
   width: 40%; 
   height: auto;
}
@media only screen and (max-width: 1000px)
{
  .pageLogo{
    width: 30%;
  }
  .main
  {     
      margin-left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 95%;
      left: 0;
      margin-left: 2%;
      top: 20%;
  }
  .popup
  {
    padding-left: 0;
    margin-left: 1%;
    margin-right: 1%;
  }
  .btn{
    width: 80%;
  }
  .user-profile-dropdown
  {
    width: 80%;
    margin: 0;
  }
}




.modal-content{
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.AttendClassButton{

  background-color: #03b4c6;
  color: white;
  font-weight: normal;
  border: none;
  border-radius: 3vh;
  width: 50%;
  padding: 2vh;
  font-size: large;
}
.AttendClassDropdown
{
  width: 50%;
  height: auto;
  padding: 2vh;
  border: none;
  margin: 2vh;

}

.dropdown-menu-scrollable {
  max-height: 300px;
  overflow-y: auto;
}
/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 300px;
}

/* Redeem Button */
.redeem-button {
  background-color: #60a1a8;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: not-allowed;
  opacity: 0.6;
  margin-top: 10px;
}

/* Close Button */
.close-button {
  background-color: #f34d4d;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.close-button:hover {
  background-color: #999;
}